import { exportPDF } from '../timesheet-summary/exportPDF';

const header = ['Employee_Name', 'Company_Subcontractor', 'Position', 'Contact_Details', 'SIA_License_No.', 'License_Expiry'];

export const convertToPDF = (jsonData, selectedDateFrom, selectedDateTo) => {
  const headers = [header];
  const title = 'Staff List';
  const data = jsonData.map(row => [
    row.Employee_Name && row.Employee_Name,
    row.Company_Subcontractor && row.Company_Subcontractor,
    row.Position && row.Position,
    row.Contact_Details && row.Contact_Details,
    row.siaLicenceNumber && row.siaLicenceNumber,
    row.License_Expiry && row.License_Expiry,
  ]);
  const filename = 'Staff Report.pdf';

  exportPDF(selectedDateFrom, selectedDateTo, headers, title, data, filename);
};
