import React from 'react';
import { makeStyles } from '@material-ui/core';
import ShiftRecordHeaderComponent from './ShiftRecordHeaderComponent';
import ShiftRecordsComponent from './ShiftRecordsComponent';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import './timeclock.scss';

export interface IAbnormalShiftsModalComponent {
    openAbnormalShiftModal: boolean;
    shifts: any;
    setAbnormalShiftModalOpen: (argument) => void;
    heading: string;
  }

  const useStyles = makeStyles(theme => ({
      paper: {
        position: 'absolute',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: '10%',
        left: '20%',
        width: '60%',
        maxHeight: '60vh',
        overflowY: 'auto',
        borderRadius: '8px',
      },
      modalContent: {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 64px)', // Adjust based on your header and padding
      },
      modalBody: {
        marginTop: theme.spacing(3),
        paddingTop: theme.spacing(2),
      },
      closeIcon: {
        cursor: 'pointer',
      },
      modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #ccc',
        paddingBottom: theme.spacing(1),
      },
      
    }  
  ));

const headers = [
  'Date/ Time',
  'Assignee Name',
  'Sign In - Sign Out',
  'Actions'
];

const activeColumns = {
  'status': false,
  'dateTime': true,
  'assigneeName': true,
  'signInOut': true,
  'customSignInOut': false,
  'breakSBreakE': false,
  'workHours': false,
  'breakTime': false,
  'actions': true
};

const AbnormalShiftsModalComponent = (props : IAbnormalShiftsModalComponent) => {
  const classes = useStyles();
  return (
    <Modal open={props.openAbnormalShiftModal} onClose={() => props.setAbnormalShiftModalOpen(false)} className="shift-abnormal-modal">
      <div> 
        <div className={classes.paper}>
        <div className={classes.modalHeader}>
          <h2 className="modal-title" id="abnormalShiftsModalLabel">
            Abnormal Shifts
          </h2>
          <div className="shift-abnormal-modal-close-button">
            <CloseIcon className={classes.closeIcon} fontSize="small" onClick={() => props.setAbnormalShiftModalOpen(false)} />
          </div>

        </div>
          <div className={classes.modalBody}>
                <ShiftRecordHeaderComponent headers={headers}/>
                <ShiftRecordsComponent {...props} activeColumns={activeColumns} />
          </div>
      </div>
     </div>
    </Modal>
  );
};
export default React.memo(AbnormalShiftsModalComponent);
