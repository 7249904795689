// Dummy
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Table, Grid } from '@material-ui/core';
import { Translate } from 'react-jhipster';
import '../../../utils/GlobalCssRequiredInWebsite.scss';
import Spinner from '../../commonUI/spinner/spinner';
import './person.scss';
import { IRootState } from 'app/shared/reducers';
import { setPastPageNumber, reset, getPastEmployee } from './person.reducer';
import IconButton from '@material-ui/core/IconButton';
import { getPersonalSettings } from '../../modules/Setting/Personal-Setting.reducer';
import SearchBar from 'material-ui-search-bar';
import Paper from '@material-ui/core/Paper';
import InfiniteScroll from 'react-infinite-scroll-component';
import TableContainer from '@material-ui/core/TableContainer';
import debounce from 'lodash.debounce';
import { dateFTConverter } from '../../../utils/TimeFormatAndTimezone';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import FormControl from '@material-ui/core/FormControl';

export interface IPersonProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const PastEmployee = (props: IPersonProps) => {
  const [dateFormat, setdateFormat] = useState(props.setting.date_format);
  const [records, setRecords] = useState(props.personList);
  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const [isSearch, setSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    props.reset();
    props.setPastPageNumber({ pageNumber: 1 });
  }, []);

  useEffect(() => {
    setdateFormat(props.setting.date_format);
  }, [props.setting]);

  const handleSyncList = () => {
    props.reset();
    props.setPastPageNumber({ pageNumber: 1 });
  };

  useEffect(() => {
    setRecords(props.personList);
  }, [props.personList]);

  const search = e => {
    props.reset();
    setSearchTerm(e);
    debouncedSearch(e);
    setSearch(true);
  };

  const debouncedSearch = useCallback(
    debounce(term => {
      props.reset();
      props.getPastEmployee(-1, term);
    }, 500),
    []
  );

  const cancelSearch = () => {
    props.reset();
    props.setPastPageNumber({ pageNumber: 1 });
    setSearch(false);
  };
  const handlePersonView = id => {
    props.history.push(`/staff/${id}`, true);
  };

  const { personList, match, loading, role } = props;
  return (
    <div className="entities-reports-main-container">
      {props.loading && <Spinner />}
      <div className="entities-reports-filter-area">
        <Grid container spacing={2} style={{ flexWrap: 'nowrap', width: '65%' }}>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <FormControl variant="outlined" size="small">
              <SearchBar
                value={searchTerm}
                onChange={e => {
                  search(e);
                }}
                onCancelSearch={() => cancelSearch()}
                placeholder="Search Here..."
                style={{
                  outline: '1px solid #bbc4c2',
                  borderRadius: '4px',
                  height: '40px',
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container className="entities_reports_refresh_and_download_div" style={{ width: '35%' }}>
          <IconButton
            size="small"
            className="report-download-button"
            id="entities_reports_btn"
            disabled={props.loading}
            onClick={handleSyncList}
          >
            <AutorenewIcon fontSize="large" />
          </IconButton>
        </Grid>
      </div>
      {/* <div className="personTopButtonsDiv">
        <div className="personTitleName">
          <Paper elevation={3}>
            <SearchBar
              className="departmentSearchBar"
              onChange={e => {
                search(e);
              }}
              value={searchTerm}
              onCancelSearch={() => cancelSearch()}
              placeholder="Search anything here .."
            />
          </Paper>
        </div>
        <div className="subcontractorCreateButtonDiv">
          <IconButton onClick={handleSyncList} id="subcontractorRefreshButton" data-cy="entityEditButton" style={{ padding: '7px 10px' }}>
            <AutorenewIcon fontSize="large" />
          </IconButton>
        </div>
      </div> */}
      <div>
        <div className="globalTableHeaderGradientColor personTableHeaderRow sticky_div">
          <div className="personTableHeaderCell pastPersonHeaderNameCell">Name</div>
          <div className="personTableHeaderCell pastPersonHeaderCompanyCell">Company</div>
          <div className="personTableHeaderCell pastPersonHeaderRoleCell">Position</div>
          <div className="personTableHeaderCell personHeaderDepartmentCell">Deleted at</div>
        </div>

        {records && records.length > 0 ? (
          <InfiniteScroll
            dataLength={personList.length}
            scrollThreshold="200px"
            next={() => {
              if (personList.length > 0 && isSearch !== true) {
                props.setPastPageNumber({ pageNumber: props.pageId['pageNumber'] + 1 });
              }
            }}
            hasMore={true}
            loader={''}
          >
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <tbody>
                  {records
                    ? records.map((person, i) => (
                        <tr key={`entity-${i}`} data-cy="entityTable" className="personTableBodyRows">
                          <td className="personTableBodyCell pastPersonTableBodyNamecell">
                            <span onClick={() => handlePersonView(person.id)} style={{ cursor: 'pointer' }}>
                              <a>
                                {person.first_name}&nbsp;{person.last_name}
                              </a>
                            </span>
                          </td>
                          <td className="personTableBody pastPersonTableBodyCompanycell">
                            {person.organizations_list && person.organizations_list.organization
                              ? person.organizations_list.organization.name
                              : Array.isArray(person.subcontractors) &&
                                person.subcontractors.map((entity, index) => (
                                  <React.Fragment key={index}>
                                    <span className={'personTable new-line'}>{entity.name}</span>
                                  </React.Fragment>
                                ))}
                          </td>
                          <td className="personTableBodyCell pastPersonTableBodyDesignationcell">
                            {person.designation ? person.designation.name : null}
                          </td>
                          <td className="personTableBodyCell pastPersonTableBodyDepartmentcell">
                            {person.organizations_list && person.organizations_list.end_date
                              ? dateFTConverter(person.organizations_list.end_date, props.setting.date_format)
                              : null}
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        ) : (
          !loading && (
            <div className="no-record-warning">
              <Translate contentKey="wfmApp.person.home.notFound">No People found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  personList: storeState.person.past_entities,
  loading: storeState.person.loading,
  setting: storeState.PersonalSettings.storedPersonalSettings,
  role: storeState.authentication.account,
  pageId: storeState.person.pageNumber,
});

const mapDispatchToProps = {
  getPastEmployee,
  //   getEntities,
  getPersonalSettings,
  setPastPageNumber,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PastEmployee);
