import React, { useEffect, useState } from 'react';
import { FormControl, MenuItem, TextField, Grid, Popper, IconButton } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { RouteComponentProps } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import SearchBar from 'material-ui-search-bar';
import { IRootState } from 'app/shared/reducers';
import styled from 'styled-components';
import '../../../utils/GlobalCssRequiredInWebsite.scss';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CustomToolbarComponent from '../service-area/CustomToolbarComponent';
import moment from 'moment';
import { getReport } from '../check-call/automated-control-room.reducer';
import {
  handleFilterCurrentWeekStartDate,
  handleFilterCurrentWeekEndDate,
  handleFilterLastWeekStartDate,
  handleFilterLastWeekEndDate,
  getSelectedTimePeriodFilter,
} from '../../../utils/TimeFormatAndTimezone';
import timePeriod from '../../shared/data/time_period_other.json';
import { getEntities as getPersons, reset as resetPerson, getPersonForFilters } from 'app/entities/person/person.reducer';
import MomentUtils from '@date-io/moment';
import { connect } from 'react-redux';
import './call-log.scss';
import { CallReportTable, SubRowAsync } from './call-log-table';
import { convertToPDF } from './pdfData';
import { dateFTConverter } from '../../../utils/TimeFormatAndTimezone';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import GetAppIcon from '@material-ui/icons/GetApp';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Spinner from 'app/commonUI/spinner/spinner';

export const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;
    width: 100%;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

export interface ICallLogsProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const CallLogs = (props: ICallLogsProps) => {
  const [values, setValues] = useState({
    people: '',
    from_date: moment(handleFilterCurrentWeekStartDate()).format('YYYY-MM-DD'),
    to_date: moment(handleFilterCurrentWeekEndDate()).format('YYYY-MM-DD'),
    newPage: 1,
  });
  const [selectedTimePeriod, setTimePeriod] = useState({ id: 1, text: 'Until now' });
  const [selectedDateTo, setSelectedDateTo] = React.useState<any | null>(
    getSelectedTimePeriodFilter(selectedTimePeriod.text, 'setSelectedDateTo')
  );
  const [selectedDateFrom, setSelectedDateFrom] = React.useState<any | null>(
    getSelectedTimePeriodFilter(selectedTimePeriod.text, 'setSelectedDateFrom')
  );
  const [selectedPerson, setSelectedPerson] = React.useState<any | null>(null);
  const [searched, setSearched] = useState<string>('');
  const [newRows, setRows] = useState(props.callLogList);
  const { callLogList, loading } = props;
  const csvLinks = [''];
  const [modalOpen, setModalOpen] = useState(false);
  const [attachmentData, setAttachmentData] = useState({ ext: '', file: '' });
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleViewIncidentAttachment = image => {
    setAttachmentData({ ext: image.ext, file: image.file });
    setModalOpen(true);
  };

  useEffect(() => {
    props.resetPerson();
    props.getPersonForFilters();
  }, []);
  useEffect(() => {
    props.getReport(values);
  }, [values]);
  useEffect(() => {
    setRows(props.callLogList);
  }, [props.callLogList]);
  const header = [
    { label: 'Date', key: 'date' },
    { label: 'Name', key: 'person' },
    { label: 'Status', key: 'contactType' },
    { label: 'Call Type', key: 'reason' },
    { label: 'Phone Number', key: 'contact' },
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: d => (
          <span>
            {d.person}
            <br />
            <span className="timesheetTypeCell">{d.type}</span>
          </span>
        ),
        SubCell: () => null,
      },
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => (
          <>
            <span {...row.getToggleRowExpandedProps()} className="ArrowRightLeftIcon">
              {row.isExpanded ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
            </span>
          </>
        ),
        SubCell: () => null,
      },
      {
        Header: 'Date/Time',
        accessor: d => (
          <span>
            <span className="timesheetStartDateCell">{d.date ? dateFTConverter(d.date, props.setting.date_format) : ''}</span>
            <br />
            <span></span>
          </span>
        ),
        SubCell: cellProps => <>{cellProps.value}</>,
      },
      {
        Header: 'Status',
        accessor: d => d.contactType,
      },
      {
        Header: 'Shift Event type',
        accessor: d => d.reason,
      },
      {
        Header: 'Phone Number',
        accessor: d => d.contact,
      },
      {
        Header: 'On-Site Pictures',
        accessor: d => (
          <span>
            {d.image && d.image.length > 0
              ? d.image.map((image, index) => (
                  <span onClick={() => handleViewIncidentAttachment(image)} className="timesheetTypeCell" key={index}>
                    <span style={{ paddingRight: '3px', cursor: 'pointer' }}>
                      <img src="../../../content/images/calllogimageicon.svg" alt={`Image ${index + 1}`} />
                    </span>
                    <span style={{ cursor: 'pointer' }}>{`${d.reason} ${index + 1}`}</span>
                  </span>
                ))
              : null}
          </span>
        ),
      },
    ],
    []
  );

  const renderRowSubComponent = React.useCallback(
    ({ row, rowProps, loading }) => <SubRowAsync row={row} rowProps={rowProps} loading={loading} />,
    []
  );

  const setTimePeriodValues = (first, second) => {
    setValues(oldValues => ({
      ...oldValues,
      ['from_date']: moment(first).format('YYYY-MM-DD'),
      ['to_date']: moment(second).format('YYYY-MM-DD'),
      ['current_day']: '',
    }));
  };

  const handleTimePeriodFilter = (_, e) => {
    if (e) {
      setTimePeriod({ id: e.id, text: e.text });

      switch (e.id) {
        case 1:
          setValues(oldValues => ({ ...oldValues, ['from_date']: '', ['to_date']: moment().format('YYYY-MM-DD'), ['current_day']: '' }));
          setSelectedDateFrom(null);
          setSelectedDateTo(null);
          break;
        case 2:
          {
            const first = moment().format('YYYY-MM-DD');
            setValues(oldValues => ({ ...oldValues, ['from_date']: first, ['to_date']: first, ['current_day']: first }));
            setSelectedDateFrom(first);
            setSelectedDateTo(first);
          }
          break;
        case 3:
          {
            const first = handleFilterCurrentWeekStartDate();
            const last = handleFilterCurrentWeekEndDate();
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        case 4:
          {
            const first = handleFilterLastWeekStartDate();
            const last = handleFilterLastWeekEndDate();
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        case 5:
          {
            const first = moment().startOf('month').format('YYYY-MM-DD');
            const last = moment().endOf('month').format('YYYY-MM-DD');
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        case 6:
          {
            const first = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
            const last = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
            setTimePeriodValues(first, last);
            setSelectedDateFrom(first);
            setSelectedDateTo(last);
          }
          break;
        default:
          setValues(oldValues => ({ ...oldValues, ['from_date']: '', ['to_date']: '', ['current_day']: '' }));
      }
    } else {
      setValues(oldValues => ({ ...oldValues, ['from_date']: '', ['to_date']: '', ['current_day']: '' }));
    }
  };

  const handleDateTo = e => {
    const d = moment(e).format('YYYY-MM-DD');
    setSelectedDateTo(e);
    if (d !== 'Invalid date') {
      setValues(oldValues => ({ ...oldValues, ['to_date']: d }));
    }
  };

  const handleDateFrom = e => {
    const d = moment(e).format('YYYY-MM-DD');
    setSelectedDateFrom(e);
    if (d !== 'Invalid date') {
      setValues(oldValues => ({ ...oldValues, ['from_date']: d }));
    }
  };
  const handlePersonFilterValues = (_, e) => {
    if (e) {
      setSelectedPerson({ id: e.id, text: e.text });
      return setFilterValues(e.name, e.id);
    } else {
      setValues(oldValues => ({ ...oldValues, people: '' }));
    }
  };
  const setFilterValues = (name, value) => {
    setValues(oldValues => ({ ...oldValues, [name]: value }));
  };

  const requestSearch = (searchedVal: string) => {
    const value = searchedVal.toLowerCase();
    const filteredRows = props.callLogList[0]['payload'].filter(data => {
      const row = { ...data['report']['0'] };
      return (
        (row.startDate && dateFTConverter(row.startDate, props.setting.date_format).includes(value)) ||
        (row.person && row.person.toLowerCase().includes(value)) ||
        (row.description && row.description.toLowerCase().includes(value)) ||
        (row.scheduledTime && row.scheduledTime.toLowerCase().includes(value)) ||
        (row.actualTime && row.actualTime.toLowerCase().includes(value)) ||
        (row.scheduledPay && row.scheduledPay.toLowerCase().includes(value)) ||
        (row.actualPay && row.actualPay.toLowerCase().includes(value))
      );
    });
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched('');
    requestSearch(searched);
  };

  const jsonData = [];
  if (props.callLogList.length > 0) {
    props.callLogList[0]['payload'].forEach(element => {
      element['report']['0']['PAYLOAD'].forEach(log => {
        jsonData.push(log);
      });
    });
  }

  const csvReport = {
    data: jsonData,
    headers: header,
    filename: 'Call_log_Report.csv',
  };
  let Tabledata = [];
  const newData = [];
  let rowData: any = [];
  const isDescending = true;
  if (newRows && newRows.length > 0) {
    if (newRows[0]['payload']) {
      rowData = newRows[0]['payload'];
    } else if (newRows[0]['report']) {
      rowData = newRows;
    }
    rowData?.forEach(element => {
      const data = { ...element['report']['0'] };
      newData.push(data);
      Tabledata = newData.sort((a, b) => {
        return isDescending
          ? new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
          : new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
      });
    });
  }

  const handleToggle = event => {
    setOpen(prevOpen => !prevOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleSync = () => {
    props.resetPerson();
    props.getReport(values);
  };
  return (
    <div className="entities-reports-main-container">
      {props.loading && <Spinner />}
      <div className="entities-reports-filter-area">
        <Grid container spacing={2} style={{ flexWrap: 'nowrap', width: '65%' }}>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <FormControl variant="outlined" size="small">
              <SearchBar
                value={searched}
                onChange={searchVal => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
                placeholder="Search Here..."
                style={{
                  outline: '1px solid #bbc4c2',
                  borderRadius: '4px',
                  height: '40px',
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                id="date"
                placeholder="From"
                inputVariant="outlined"
                className="entities_report_filter_fields datePicker"
                name="from_date"
                size="small"
                ToolbarComponent={prop => (
                  <>
                    <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                  </>
                )}
                labelFunc={selectedDateFrom ? dateFTConverter : () => 'From'}
                value={selectedDateFrom ? moment(selectedDateFrom).format('YYYY-MM-DD') : null}
                onChange={handleDateFrom}
                InputLabelProps={{ shrink: true }}
                disabled={loading}
              />{' '}
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <KeyboardDatePicker
                id="date"
                size="small"
                inputVariant="outlined"
                ToolbarComponent={prop => (
                  <>
                    <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                  </>
                )}
                labelFunc={selectedDateTo ? dateFTConverter : () => 'Till'}
                value={selectedDateTo ? moment(selectedDateTo).format('YYYY-MM-DD') : null}
                className="entities_report_filter_fields datePicker"
                name="to_date"
                placeholder="Till"
                onChange={handleDateTo}
                InputLabelProps={{ shrink: true }}
                disabled={loading}
              />{' '}
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <FormControl variant="outlined" fullWidth className="entities_report_filter_fields staffFilterTimeSheet">
              <Autocomplete
                options={
                  props.personsList &&
                  props.personsList.map(person => ({
                    id: person.id,
                    text: person.first_name + ' ' + person.last_name,
                    name: 'people',
                  }))
                }
                noOptionsText={'No Records'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.text || ''}
                onChange={handlePersonFilterValues}
                value={selectedPerson}
                disabled={loading}
                renderInput={params => <TextField {...params} variant="outlined" size="small" placeholder="Staff" />}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container className="entities_reports_refresh_and_download_div" style={{ width: '35%' }}>
          <IconButton
            size="small"
            className="report-download-button"
            id="entities_reports_btn"
            onClick={handleToggle}
            disabled={props.loading}
          >
            <GetAppIcon fontSize="large" />
            {open && (
              <div className="export-menu">
                <MenuItem>
                  <div style={{ display: 'block', color: 'black' }}>
                    <span style={{ textAlign: 'left' }} onClick={() => convertToPDF(true, jsonData, selectedDateFrom, selectedDateTo)}>
                      Export PDF
                    </span>
                    <br />
                    <br />
                    <span style={{ textAlign: 'left' }}>
                      <CSVLink className="csvLinks" {...csvReport}>
                        Export CSV
                      </CSVLink>
                    </span>
                  </div>
                </MenuItem>
              </div>
            )}
          </IconButton>

          <IconButton
            size="small"
            className="report-download-button"
            id="entities_reports_btn"
            disabled={props.loading}
            onClick={handleSync}
          >
            <AutorenewIcon fontSize="large" />
          </IconButton>

          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <FormControl variant="outlined" fullWidth>
              <Autocomplete
                options={
                  timePeriod &&
                  timePeriod.map(time => ({
                    id: time.value,
                    text: time.label,
                  }))
                }
                noOptionsText={'--Period--'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.text || ''}
                onChange={handleTimePeriodFilter}
                value={selectedTimePeriod}
                disabled={loading}
                fullWidth
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    className="personAutocompleteTextfield entities_report_filter_fields"
                    placeholder="Time period"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>

      <div>
        <CallReportTable
          columns={columns}
          data={Tabledata}
          calLogsList={callLogList}
          renderRowSubComponent={renderRowSubComponent}
          loading={loading}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (setState: IRootState) => ({
  callLogList: setState.AutomatedControlRoom.entities,
  setting: setState.PersonalSettings.storedPersonalSettings,
  loading: setState.AutomatedControlRoom.loading,
  personsList: setState.person.filterPersons,
});

const mapDispatchToProps = {
  getReport,
  getPersons,
  resetPerson,
  getPersonForFilters,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CallLogs);
