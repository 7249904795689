import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table, Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../../utils/GlobalCssRequiredInWebsite.scss';
import IconButton from '@material-ui/core/IconButton';
import './subcontractor.scss';
import { IRootState } from 'app/shared/reducers';
import { getAllEntities, setPageNumber, reset } from './subcontractor.reducer';
import SearchBar from 'material-ui-search-bar';
import Paper from '@material-ui/core/Paper';
import InfiniteScroll from 'react-infinite-scroll-component';
import TableContainer from '@material-ui/core/TableContainer';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import GetAppIcon from '@material-ui/icons/GetApp';
import FormControl from '@material-ui/core/FormControl';
import { subcontractorConvertToPDF } from './subcontractorConvertToPDF';
import Spinner from 'app/commonUI/spinner/spinner';

export interface ISubcontractorProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const Subcontractor = (props: ISubcontractorProps) => {
  const [searched, setSearched] = useState<string>('');
  const [records, setRecords] = useState(props.subcontractorList);

  const jsonData = [];
  if (records && records.length > 0) {
    records.map(elem => {
      const SubcontractorData = { ...elem };
      jsonData.push(SubcontractorData);
    });
  }

  useEffect(() => {
    props.reset();
    props.setPageNumber({ pageNumber: 1 });
  }, []);

  const handleSyncList = () => {
    props.reset();
    props.setPageNumber({ pageNumber: 1 });
  };

  useEffect(() => {
    setRecords(props.subcontractorList);
  }, [props.subcontractorList]);

  const requestSearch = (searchedVal: string) => {
    const value = searchedVal.toLowerCase();
    const filteredRows = props.subcontractorList.filter(row => {
      return (
        (row.id !== null && row.id.toString().includes(value)) ||
        (row.name !== null && row.name.toLowerCase().includes(value)) ||
        (row.contactPerson !== null && row.contactPerson.toLowerCase().includes(value)) ||
        (row.phone !== null && row.phone.toString().includes(value)) ||
        (row.email !== null && row.email.toLowerCase().includes(value)) ||
        (row.city !== null && row.city.name.toLowerCase().includes(value))
      );
    });
    setRecords(filteredRows);
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch(searched);
  };

  const { subcontractorList, match, loading, permissions } = props;
  return (
    <div className="entities-reports-main-container">
      {loading && <Spinner />}
      <div className="entities-reports-filter-area">
        <Grid container spacing={2} style={{ flexWrap: 'nowrap', width: '65%' }}>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <FormControl variant="outlined" size="small">
              <SearchBar
                value={searched}
                onChange={searchVal => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
                placeholder="Search Here..."
                style={{
                  outline: '1px solid #bbc4c2',
                  borderRadius: '4px',
                  height: '40px',
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container className="entities_reports_refresh_and_download_div" style={{ width: '35%' }}>
          <IconButton
            size="small"
            className="report-download-button"
            id="entities_reports_btn"
            onClick={() => subcontractorConvertToPDF(jsonData)}
            disabled={props.loading}
          >
            <GetAppIcon fontSize="large" />
          </IconButton>

          <IconButton
            size="small"
            className="report-download-button"
            id="entities_reports_btn"
            disabled={props.loading}
            onClick={handleSyncList}
          >
            <AutorenewIcon fontSize="large" />
          </IconButton>

          {/* <Grid item xl={4} lg={4} md={4} sm={4} xs={4}> */}
          <Link
            to={`${match.url}/new`}
            className="btn jh-create-entity serviceAreaCreateButton incidentCreateButton"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp; Create New
          </Link>
          {/* </Grid> */}
        </Grid>
      </div>

      <div>
        <div className="globalTableHeaderGradientColor subcontractorTableHeaderRow sticky_div">
          <div className="subcontractorTableHeaderCell subcontractorHeaderIdCell">ID No.</div>
          <div className="subcontractorTableHeaderCell subcontractorHeaderNameCell">Subcontractor Name</div>
          <div className="subcontractorTableHeaderCell subcontractorHeaderContactPersonCell">Point of Contact</div>
          <div className="subcontractorTableHeaderCell subcontractorHeaderContactDetailsCell">Contact Details</div>
          <div className="subcontractorTableHeaderCell subcontractorHeaderRegionCell">Region</div>
        </div>
        <InfiniteScroll
          dataLength={subcontractorList.length}
          scrollThreshold="200px"
          next={() => {
            if (subcontractorList.length > 0) {
              props.setPageNumber({ pageNumber: props.pageId['pageNumber'] + 1 });
            }
          }}
          hasMore={true}
          loader={''}
        >
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <tbody>
                {records && records.length > 0
                  ? records.map((subcontractor, i) => (
                      <tr key={`entity-${i}`} data-cy="entityTable" className="subcontractorTableBodyRows">
                        <td className="subcontractorTableBodyCell subcontractorTableBodyIdcell">{i + 1}</td>
                        <td className="subcontractorTableBodyCell subcontractorTableBodyNamecell">
                          {<a href={`${match.url}/${subcontractor.id}`}>{subcontractor.name}</a>}
                        </td>
                        <td className="subcontractorTableBodyCell subcontractorTableBodyContactPersoncell">
                          {subcontractor.contactPerson}
                        </td>
                        <td className="subcontractorTableBodyCell subcontractorTableBodyContactDetailscell">
                          {subcontractor.phone}
                          <br />
                          <span className="subcontractorEmailBodyCell">{subcontractor.email}</span>
                        </td>
                        <td className="subcontractorTableBodyCell subcontractorTableBodyRegioncell">
                          {subcontractor.city && subcontractor.city.name}
                        </td>
                      </tr>
                    ))
                  : !props.loading && <div className="no-record-warning">Subcontractor not found</div>}
              </tbody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </div>
    </div>
  );
};

const mapStateToProps = ({ subcontractor, authentication }: IRootState) => ({
  subcontractorList: subcontractor.entities,
  loading: subcontractor.loading,
  permissions: authentication.account.permission,
  pageId: subcontractor.pageNumber,
});

const mapDispatchToProps = {
  getAllEntities,
  setPageNumber,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Subcontractor);
