import React, { useState, useEffect } from 'react';
import {
  InputLabel,
  TextField,
  Grid,
  TextareaAutosize,
  Button,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getApplicantDetailsForPersonalReferenceVerification, storePersonalReferenceVerification } from '../ApplicantVerification.reducer';
import { getAllCountry } from 'app/entities/person/person.reducer';
import { getCity } from 'app/entities/sub-contractor/subcontractor.reducer';
import { IRootState } from 'app/shared/reducers';
import './PersonalRferencesVerification.scss';
import { connect } from 'react-redux';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CustomToolbarComponent from '../../../entities/service-area/CustomToolbarComponent';
import DateFnsUtils from '@date-io/date-fns';
import { getDateFormat } from '../../../shared/util/calender-utils';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import axios from 'axios';
import { Toast, toast } from 'react-toastify';

type Country = {
  id: number;
  name: string;
  iso_code: string | null;
  address_unit_identifier: string | null;
  services: boolean;
};

type FormData = {
  personKnows: boolean;
  knownFrom: string;
  knownTill: string;
  relationToApplicant: string;
  name: string;
  postCode: string;
  city: string | null;
  country: string | null;
  comment: string;
  infoVerified: boolean;
  reference_verification_details_id: string;
};

export const PersonalReferenceVerificationForm = props => {
  const prefix = 'personal-references-verification-form';
  const [countriesManual, setcountriesManual] = useState<Country[]>([]);
  const [allCity, setAllCity] = useState([]);
  const [values, setValues] = useState({});
  const [formData, setFormData] = useState<FormData>({
    personKnows: false,
    knownFrom: '',
    knownTill: '',
    relationToApplicant: '',
    name: '',
    postCode: '',
    city: '',
    country: '',
    comment: '',
    infoVerified: false,
    reference_verification_details_id: '',
  });
  const [fieldError, setFieldError] = useState({
    know_error: false,
    startDateField_error: false,
    endDateField_error: false,
  });
  const [personalVerification, setPersonalVerification] = useState({
    know: false,
    start_date: null,
    end_date: null,
  });

  useEffect(() => {
    props.getApplicantDetailsForPersonalReferenceVerification(props.match.params.id);
    props.getAllCountry();
  }, []);
  useEffect(() => {
    setcountriesManual(props.allcountries);
  }, [props.allcountries]);

  useEffect(() => {
    if (props.applicant) {
      setValues(oldValues => ({ ...oldValues, ['id']: props.match.params.id, ['reference_verification_id']: props.applicant?.id }));
      setFormData(oldValues => ({ ...oldValues, ['id']: props.match.params.id, ['reference_verification_id']: props.applicant?.id }));
      setFormData({ ...formData, reference_verification_details_id: props.match.params.id });
    }
  }, [props.applicant]);

  const submit = () => {
    props.storePersonalReferenceVerification(values);
  };

  const handleStartDate = event => {
    const d = moment(event).format('YYYY-MM-DD');
    setPersonalVerification(oldValues => ({ ...oldValues, start_date: event }));
    setFormData({ ...formData, knownFrom: d });
    if (personalVerification.start_date !== null || personalVerification.start_date !== undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['startDateField_error']: false }));
    }
  };

  const handleEndDate = event => {
    const d = moment(event).format('YYYY-MM-DD');
    setPersonalVerification(oldValues => ({ ...oldValues, end_date: event }));
    setFormData({ ...formData, knownTill: d });
    if (personalVerification.end_date !== null || personalVerification.end_date !== undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['endDateField_error']: false }));
    }
  };

  const handleApplicantKnow = event => {
    setPersonalVerification(oldValues => ({ ...oldValues, know: event.target.value === 'true' }));
    if (personalVerification.know !== null || personalVerification.know !== undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['know_error']: false }));
    }
  };

  const { applicant } = props;

  const isFormDataEmpty = data => {
    const emptyFields = Object.entries(data).filter(([fieldName, value]) => {
      // Exclude "infoVerified" and "relationToApplicant" fields from the check
      if (fieldName === 'infoVerified' || fieldName === 'personKnows') {
        return false;
      }
      return !value;
    });
    return emptyFields.length > 0;
  };

  useEffect(() => {
    setAllCity(props.cities);
  }, [props.cities]);

  const handleCountry = (event, newValue) => {
    if (newValue) {
      props.getCity(newValue.id);
    }
    setFormData({ ...formData, country: newValue ? newValue.name : null });
  };

  const handleCity = (event, newValue) => {
    setFormData({ ...formData, city: newValue ? newValue.name : null });
  };

  const handleSubmit = async () => {
    try {
      if (isFormDataEmpty(formData)) {
        toast.error('All fields must be filled!');
      } else {
        const response = await axios.post('/api/storePersonalReferenceVerification', formData);
        toast.success('Reference form submitted successfully!');
      }
    } catch (error) {
      toast.error('Error Occured!', error);
    }
  };

  return (
    <div className={`${prefix}-main-div`} style={{ fontFamily: 'Roboto' }}>
      {applicant && Object.keys(applicant).length > 0 ? (
        <>
          <div className="container">
            <div className="row ">
              <div className="col-12 order-2 order-lg-1 col-lg-8  ">
                <h4 className="h6 h4-md fw-bold">
                  We would appreciate your help and ask that you kindly complete the below questionnaire as soon as possible!
                </h4>

                <div className="mt-3">
                  <h6 className="h6 mb-4">Please confirm do you know the applicant?</h6>
                  <div className="d-flex align-items-center gap-custom">
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        id="personKnowYes"
                        name="personKnow"
                        className="custom-control-input"
                        value="true"
                        checked={formData.personKnows === true}
                        onChange={e => setFormData({ ...formData, personKnows: !!e.target.value })}
                      />
                      <label className="custom-control-label" htmlFor="personKnowYes">
                        Yes
                      </label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        id="personKnowNo"
                        name="personKnow"
                        className="custom-control-input"
                        value=""
                        checked={formData.personKnows === false}
                        onChange={e => setFormData({ ...formData, personKnows: !!e.target.value })}
                      />
                      <label className="custom-control-label" htmlFor="personKnowNo">
                        No
                      </label>
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <h6 className="h6 mb-4">How long you have been known to each other?</h6>
                  <div className="d-flex flex-column flex-sm-row align-items-center gap-short">
                    <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        autoOk
                        fullWidth
                        variant="inline"
                        size="small"
                        inputVariant="outlined"
                        label="From"
                        format={'MM/dd/yyyy'}
                        value={personalVerification.start_date}
                        className="personalVerificationStartDate"
                        ToolbarComponent={prop => (
                          <>
                            <CustomToolbarComponent {...prop} settingFormat={'MM/dd/yyyy'} />
                          </>
                        )}
                        placeholder={'MM/dd/yyyy'}
                        InputAdornmentProps={{ position: 'end' }}
                        onChange={date => handleStartDate(date)}
                        error={fieldError['startDateField_error']}
                      />
                    </MuiPickersUtilsProvider>
                    <p className="text-muted m-0">-</p>
                    <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        autoOk
                        fullWidth
                        variant="inline"
                        size="small"
                        inputVariant="outlined"
                        label="Till"
                        format={'MM/dd/yyyy'}
                        value={personalVerification.end_date}
                        className="personalVerificationEndDate"
                        ToolbarComponent={prop => (
                          <>
                            <CustomToolbarComponent {...prop} settingFormat={'MM/dd/yyyy'} />
                          </>
                        )}
                        placeholder={'MM/dd/yyyy'}
                        InputAdornmentProps={{ position: 'end' }}
                        onChange={date => handleEndDate(date)}
                        minDate={moment(formData.knownFrom)}
                        maxDate={moment()}
                        error={fieldError['endDateField_error']}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>

                <div className="mt-4">
                  <h6 className="h6 mb-4">Please state your relationship to the applicant:</h6>

                  <div className="d-flex flex-column" style={{ gap: '2rem' }}>
                    {/* <select
                      className="form-control"
                      id="relation"
                      onChange={e => setFormData({ ...formData, relationToApplicant: e.target.value })}
                    >
                      <option>Colleague</option>
                      <option>Friend</option>
                      <option>Manager</option>
                    </select> */}

                    <Autocomplete
                      fullWidth
                      size="small"
                      className="custom-muiFields"
                      noOptionsText={'no records'}
                      options={['Colleague', 'Friend', 'Manager']}
                      getOptionLabel={option => option || null}
                      value={formData.relationToApplicant}
                      onChange={(e, newValue) => setFormData({ ...formData, relationToApplicant: newValue })}
                      renderInput={params => <TextField {...params} label="Realtion" variant="outlined" />}
                    />

                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Name"
                      onChange={e => setFormData({ ...formData, name: e.target.value })}
                    />

                    <div className="d-flex  flex-column flex-sm-row align-items-center justify-content-between gap-short">
                      <input
                        type="number"
                        inputMode="numeric"
                        className="form-control mb-4  mb-sm-0"
                        placeholder="Post Code"
                        onChange={e => setFormData({ ...formData, postCode: e.target.value })}
                      />

                      <input
                        type="text"
                        className="form-control"
                        placeholder="Country"
                        onChange={e => setFormData({ ...formData, country: e.target.value })}
                      />

                      <input
                        type="text"
                        className="form-control"
                        placeholder="City"
                        onChange={e => setFormData({ ...formData, city: e.target.value })}
                      />

                      {/* <Autocomplete
                        fullWidth
                        size="small"
                        className="custom-muiFields"
                        noOptionsText={allCity? 'Please Select Country First': 'No Cities were found!'}
                        loading={props.cityLoading}
                        options={allCity}
                        getOptionLabel={option => option.name || null}
                        getOptionSelected={(option, value) => option.name === (value ? value.name : null)}
                        value={allCity.find(c => c.name === formData.city) || null}
                        onChange={handleCity}
                        renderInput={params => <TextField {...params} label="City" variant="outlined" />}
                      /> */}
                    </div>

                    <div className="mt-3">
                      <label htmlFor="Textarea1" className="font-weight-bold">
                        Any comments (Optional)
                      </label>
                      <textarea
                        className="form-control"
                        id="Textarea1"
                        rows={3}
                        placeholder="Write comments"
                        onChange={e => setFormData({ ...formData, comment: e.target.value })}
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="my-3">
                  <h6 className="h6 mb-4">
                    The above information has been provided without prejudice and is correct to the best of my knowledge and belief.
                  </h6>
                  <div className="d-flex align-items-center gap-custom">
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        id="KnowYes"
                        name="writeInfo"
                        className="custom-control-input"
                        checked={formData.infoVerified === true}
                        value="true"
                        onChange={e => setFormData({ ...formData, infoVerified: !!e.target.value })}
                      />
                      <label className="custom-control-label" htmlFor="KnowYes">
                        Yes
                      </label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        id="KnowNo"
                        name="writeInfo"
                        className="custom-control-input"
                        checked={formData.infoVerified === false}
                        value=""
                        onChange={e => setFormData({ ...formData, infoVerified: !!e.target.value })}
                      />
                      <label className="custom-control-label" htmlFor="KnowNo">
                        No
                      </label>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-center my-5" style={{ gap: '2rem' }}>
                  <button type="button" className="btn btn-light custom-button">
                    Cancel
                  </button>
                  <button type="button" className="btn btn-success custom-button2" onClick={handleSubmit}>
                    Save & Submit
                  </button>
                </div>
              </div>

              {/* ======= SideBar ======= */}
              <div className=" col-12 order-1 order-lg-2 col-lg-4">
                <div className="sidebar-inner-div">
                  <div>
                    <h6 className="font-weight-bold c-heading">Applicant Info:</h6>
                    <p className="custom-text">{applicant.name}</p>
                  </div>
                  <div>
                    <h6 className="font-weight-bold c-heading">Post Applied:</h6>
                    <p className="custom-text">Not Found</p>
                  </div>
                  <div className="mt-lg-2">
                    <h6 className="font-weight-bold c-heading">Screener infor:</h6>
                    <p className="m-0 custom-text">{applicant.screener_name}</p>
                    <p className="custom-text">{applicant.screener_email}</p>
                  </div>
                  <div className="mt-lg-2">
                    <h6 className="font-weight-bold c-heading">Referee:</h6>
                    <p className="m-0 custom-text">Not Found</p>
                    <p className="custom-text">Not Found</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="lds-roller"></div>
      )}
    </div>
  );
};

const mapStateToProps = ({ VettingReferenceVerification, subcontractor, person }: IRootState) => ({
  applicant: VettingReferenceVerification.personalReferenceDetails,
  cities: subcontractor.CityEntity,
  allcountries: person.AllCountryEntity,
  cityLoading: subcontractor.loading,
});

const mapDispatchToProps = {
  getApplicantDetailsForPersonalReferenceVerification,
  storePersonalReferenceVerification,
  getCity,
  getAllCountry,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PersonalReferenceVerificationForm);
