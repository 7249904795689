import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import FormControl from '@material-ui/core/FormControl';
import { getEntities as getPersons, reset as resetPerson, getPersonForFilters } from 'app/entities/person/person.reducer';
import { getEntities as getLocations, reset as resetLoc } from 'app/entities/service-area/service-area.reducer';
import { getReport } from './banned-people.reducer';
import { CSVLink } from 'react-csv';
import { getPersonalSettings } from '../../modules/Setting/Personal-Setting.reducer';
import { getOrganizationSettings } from '../../modules/Setting/generalSettings.reducer';
import '../../../utils/GlobalCssRequiredInWebsite.scss';
import { Storage } from 'react-jhipster';
import { Table } from './banned-react-table';
import { Autocomplete } from '@material-ui/lab';
import { Grid, IconButton, MenuItem, Popper, TextField } from '@material-ui/core';
import { dateFTConverter } from '../../../utils/TimeFormatAndTimezone';
import SearchBar from 'material-ui-search-bar';
import { convertToPDF } from './convertToPDF';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import GetAppIcon from '@material-ui/icons/GetApp';
import Spinner from 'app/commonUI/spinner/spinner';

export interface IBannedPeopleProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const BannedPeople = (props: IBannedPeopleProps) => {
  const [values, setValues] = useState({
    people: '',
    location: '',
  });
  const [selectedPerson, setSelectedPerson] = React.useState<any | null>(null);
  const [selectedLocation, setSelectedLocation] = React.useState<any | null>(null);
  const TimeFormat = Storage.local.get('PersonalSetting').time_format;
  const Timezone = Storage.local.get('PersonalSetting').timezone;
  const DateFormat = Storage.local.get('PersonalSetting').date_format;
  const [timeFormatSettings, setTimeFormatSettings] = useState(TimeFormat);
  const [dateFormat, setdateFormat] = useState(DateFormat);
  const [timezoneFormat, setTimezoneFormat] = useState(Timezone);
  const [currency, setCurrency] = useState(props.settings.currency);
  const { loading } = props;
  const [searched, setSearched] = useState<string>('');
  const [newRows, setRows] = useState(props.bannedPeopleList);
  const csvLinks = [''];
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    props.resetLoc();
    props.resetPerson();
    props.getPersonForFilters();
    props.getLocations(-1, '');
  }, []);

  useEffect(() => {
    props.getReport(values);
  }, [values]);

  useEffect(() => {
    setCurrency(props.settings.currency);
  }, [props.settings]);

  useEffect(() => {
    setRows(props.bannedPeopleList);
  }, [props.bannedPeopleList]);

  useEffect(() => {
    setdateFormat(props.setting.date_format);
    setTimeFormatSettings(props.setting.time_format);
    setTimezoneFormat(props.setting.timezone);
  }, [props.setting]);

  const header = [
    { label: 'Employee Name', key: 'person' },
    { label: 'Service Area', key: 'serviceArea' },
    { label: 'Start Date', key: 'start_date' },
    { label: 'End Date', key: 'end_date' },
    { label: 'Description', key: 'description' },
  ];

  const jsonData = [];
  let csvReportData = [];
  if (newRows && newRows.length > 0) {
    newRows.forEach(element => {
      const data = { ...element };
      data.start_date = data.start_date !== null ? dateFTConverter(data.start_date, props.setting.date_format) : '';
      data.end_date = data.end_date !== null ? dateFTConverter(data.end_date, props.setting.date_format) : '';
      jsonData.push(data);
    });
  }

  csvReportData = JSON.parse(JSON.stringify(jsonData));
  csvReportData.map(item => {
    item.person = item.person && item.person.first_name + ' ' + item.person.last_name;
    item.serviceArea = item.serviceArea && item.serviceArea.name;
  });

  const csvReport = {
    data: csvReportData,
    headers: header,
    filename: 'Banned_People_Report.csv',
  };

  const handleFilterValues = (_, e) => {
    if (e) {
      switch (e.name) {
        case 'people':
          setSelectedPerson({ id: e.id, text: e.text });
          return setFilterValues(e.name, e.id);

        case 'location':
          setSelectedLocation({ id: e.id, text: e.text });
          return setFilterValues(e.name, e.id);
        default:
          return null;
      }
    } else {
      setValues(oldValues => ({ ...oldValues, ['']: null }));
    }
  };

  const setFilterValues = (name, value) => {
    setValues(oldValues => ({ ...oldValues, [name]: value }));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Employee Name',
        accessor: d => <span>{d.person && d.person.first_name + ' ' + d.person.last_name}</span>,
      },
      {
        Header: 'Service Area',
        accessor: d => <span>{d.serviceArea && d.serviceArea.name}</span>,
      },
      {
        Header: 'Start Date',
        accessor: d => <span>{d.start_date}</span>,
      },
      {
        Header: 'End Date',
        accessor: d => <span>{d.end_date ? d.end_date : 'Untill Further Notice'}</span>,
      },
      {
        Header: 'Description',
        accessor: d => <span>{d.description}</span>,
      },
    ],
    []
  );

  const requestSearch = (searchedVal: string) => {
    const value = searchedVal.toLowerCase();
    const filteredRows = props.bannedPeopleList.filter(data => {
      const row = { ...data };
      return (
        (row.start_date && dateFTConverter(row.start_date, props.setting.date_format).includes(value)) ||
        (row.end_date && dateFTConverter(row.end_date, props.setting.date_format).includes(value)) ||
        (row.person && row.person.first_name && row.person.first_name.toLowerCase().includes(value)) ||
        (row.person && row.person.last_name && row.person.last_name.toLowerCase().includes(value)) ||
        (row.person &&
          row.person.first_name &&
          row.person.last_name &&
          `${row.person.first_name} ${row.person.last_name}`.toLowerCase().includes(value)) ||
        (row.description && row.description.toLowerCase().includes(value)) ||
        (row.serviceArea && row.serviceArea.name.toLowerCase().includes(value))
      );
    });
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch(searched);
  };

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleSync = () => {
    props.resetPerson();
    props.getReport(values);
  };

  const handleToggle = event => {
    setOpen(prevOpen => !prevOpen);
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className="entities-reports-main-container">
      {props.loading && <Spinner />}
      <div className="entities-reports-filter-area">
        <Grid container spacing={2} style={{ flexWrap: 'nowrap', width: '65%' }}>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
            <FormControl variant="outlined" size="small">
              <SearchBar
                value={searched}
                onChange={searchVal => requestSearch(searchVal)}
                onCancelSearch={() => cancelSearch()}
                placeholder="Search Here..."
                style={{
                  outline: '1px solid #bbc4c2',
                  borderRadius: '4px',
                  height: '40px',
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <FormControl variant="outlined" fullWidth className="entities_report_filter_fields">
              <Autocomplete
                options={
                  props.personsList &&
                  props.personsList.map(person => ({
                    id: person.id,
                    text: person.first_name + ' ' + person.last_name,
                    name: 'people',
                  }))
                }
                noOptionsText={'No Records'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.text || ''}
                onChange={handleFilterValues}
                value={selectedPerson}
                disabled={loading}
                renderInput={params => <TextField {...params} variant="outlined" size="small" placeholder="Staff" fullWidth />}
              />
            </FormControl>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
            <FormControl variant="outlined" fullWidth className="entities_report_filter_fields staffFilterTimeSheet">
              <Autocomplete
                options={
                  props.locationsList &&
                  props.locationsList.map(location => ({
                    id: location.id,
                    text: location.name,
                    name: 'location',
                  }))
                }
                noOptionsText={'No Records'}
                classes={{ listbox: 'autocompletelistbox' }}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.text || ''}
                onChange={handleFilterValues}
                value={selectedLocation}
                disabled={loading}
                renderInput={params => <TextField {...params} variant="outlined" size="small" placeholder="Service Areas" fullWidth />}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container className="entities_reports_refresh_and_download_div" style={{ width: '35%' }}>
          <IconButton
            size="small"
            className="report-download-button"
            id="entities_reports_btn"
            onClick={handleToggle}
            disabled={props.loading}
          >
            <GetAppIcon fontSize="large" />
            {open && (
              <div className="export-menu">
                <MenuItem>
                  <div style={{ display: 'block', color: 'black' }}>
                    <span style={{ textAlign: 'left' }} onClick={() => convertToPDF(jsonData, '', '')}>
                      Export PDF
                    </span>
                    <br />
                    <br />
                    <span style={{ textAlign: 'left' }}>
                      <CSVLink className="csvLinks" {...csvReport}>
                        Export CSV
                      </CSVLink>
                    </span>
                  </div>
                </MenuItem>
              </div>
            )}
          </IconButton>

          <IconButton size="small" id="entities_reports_btn" disabled={props.loading} onClick={handleSync}>
            <AutorenewIcon fontSize="large" />
          </IconButton>
        </Grid>
      </div>

      <div>
        <Table columns={columns} data={jsonData} loading={loading} />
      </div>
    </div>
  );
};

const mapStateToProps = (setState: IRootState) => ({
  bannedPeopleList: setState.bannedPeople.entities,
  personsList: setState.person.filterPersons,
  locationsList: setState.serviceArea.entities,
  loading: setState.bannedPeople.loading,
  setting: setState.PersonalSettings.storedPersonalSettings,
  settings: setState.organizationSettings.storedOrganizationSettings,
});

const mapDispatchToProps = {
  getReport,
  getPersons,
  getLocations,
  getPersonalSettings,
  getOrganizationSettings,
  resetLoc,
  resetPerson,
  getPersonForFilters,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BannedPeople);
