import React, { useEffect, useState } from 'react';
import { ActionButtons } from '../actionButtons/actionButtons';
import SignatureCanvas from 'react-signature-canvas';
import Button from '@material-ui/core/Button';
import '../../form.scss';

interface Props {
  storeVettingConsent: (data: any) => void;
  personalInfo: any;
}

export const DataProtection: React.FC<Props> = props => {
  const [signature, setSignature] = useState(null);
  const [signatureSubmitted, setSignatureSubmitted] = useState(false);
  const [signaturePad, setSignaturePad] = useState(null);
  const [signatureName, setSignatureName] = useState();
  const { personal_information } = props['employeeFormData'];
  const isEdit = props['editEmployeeForm'];

  useEffect(() => {
    if (isEdit && personal_information) {
      setSignatureName(personal_information.vetting_consent);
    }
  }, [personal_information]);

  const clear = () => {
    signaturePad.clear();
  };

  const submit = () => {
    const object = new FormData();
    object.append('signature', signature);
    object.append('person_id', props.personalInfo.id);
    props.storeVettingConsent(object);
  };

  const trim = () => {
    const canvas = signaturePad.getCanvas();
    canvas.toBlob(blob => {
      setSignature(blob);
    });
    setSignatureSubmitted(true);
  };

  return (
    <fieldset id="data_protection_section">
      <div className="job-application-form-sections-top-heading"> Declaration</div>
      <hr style={{ marginTop: '1rem', marginBottom: '1rem' }} />
      <div className="form-group">
        <p style={{ textAlign: 'justify' }}>
          I understand that employment with this company is subject to references and screening in accordance with BS 7858.
        </p>
        <p style={{ textAlign: 'justify' }}>
          I confirm that the information I have provided on my application is true and complete to the best of my knowledge. I understand
          and agree that I will be subject to any or all of the following checks:
        </p>
        <ul>
          <li> Address Check </li>
          <li> Financial probity check which the company will retain on file </li>
          <li> ID verification checks </li>
          <li> Academic/Professional qualification check </li>
          <li> Employment history, including any periods of unemployment/self-employment and any gaps </li>
          <li> Criminal background check </li>
        </ul>
        <p style={{ textAlign: 'justify' }}>
          I authorize the company or its agents to approach government agencies, former employers, educational establishments, for
          information relating to and verification of my employment or unemployment history, a consumer information search and ID check with
          a credit reference agency, which will keep a record of those searches in line with current legislation.
        </p>
        <p style={{ textAlign: 'justify' }}>
          I further declare that any documents that I provide as proof of my identity, proof of address, and any other documents that I
          provide are genuine and that any falsified documents may be reported to the appropriate authority. I understand that it may be a
          criminal offence to attempt to obtain employment by deception and that any misrepresentation, omission of material fact or
          deception will be cause for immediate withdrawal of any offer of employment.
        </p>
        <p style={{ textAlign: 'justify' }}>
          I accept that I may be required to undergo a medical examination where requested by the company. I understand and agree that if so
          required I will make a statutory declaration in accordance with the provisions of the Statutory Declarations Act 1835, in
          confirmation of previous employment or unemployment.
        </p>
        <p style={{ textAlign: 'justify' }}>
          I understand that any false statement or omission to the company or its representatives may render me liable to dismissal without
          notice.
        </p>
        <p style={{ textAlign: 'justify' }}>
          By signing this declaration, I agree that I have provided complete and true information in support of the application and that I
          understand that knowingly making a false statement for this purpose is a criminal offence.
        </p>
        <p style={{ textAlign: 'justify' }}>
          <b> Please sign to confirm you agree to the above processing terms: </b>
        </p>
      </div>
      <div className="vetting-consent-applicant-information-div">
        <div className="vetting-consent-applicant-name-div">
          {' '}
          <b> Applicant Signature : </b>{' '}
        </div>
        <div className="vetting-consent-applicant-signature-div">
          <SignatureCanvas
            penColor="green"
            canvasProps={{ width: 1000, height: 100, className: 'sigCanvas' }}
            ref={ref => {
              setSignaturePad(ref);
            }}
          />
        </div>
        <div style={{ marginTop: '1%' }}>{signatureName}</div>
        {signatureSubmitted === true && <div>Signature Submitted Succesfully</div>}
        <div style={{ marginTop: '1%' }}>
          {signatureSubmitted === false && (
            <Button onClick={clear} style={{ border: '1px solid grey' }}>
              {' '}
              Clear{' '}
            </Button>
          )}
          &nbsp;&nbsp;
          <Button onClick={trim} style={{ border: '1px solid grey' }}>
            {' '}
            Done{' '}
          </Button>
        </div>
      </div>
      <br />
      <ActionButtons backButton={true} buttonText={true} submit={submit} />
    </fieldset>
  );
};
