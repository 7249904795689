import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table, Grid } from '@material-ui/core';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntities, reset, setPageNumber } from './department.reducer';
import { IDepartment } from 'app/shared/model/department.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import SearchBar from 'material-ui-search-bar';
import Paper from '@material-ui/core/Paper';
import InfiniteScroll from 'react-infinite-scroll-component';
import TableContainer from '@material-ui/core/TableContainer';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import GetAppIcon from '@material-ui/icons/GetApp';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { deparmentConvertToPDF } from './departmentConvertToPDF';
import FormControl from '@material-ui/core/FormControl';
import Spinner from 'app/commonUI/spinner/spinner';

export interface IDepartmentProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const Department = (props: IDepartmentProps) => {
  const [dateFormat, setdateFormat] = useState(1);
  const [disabled, setDisabled] = useState(0);
  const [searched, setSearched] = useState<string>('');
  const [records, setRecords] = useState(props.departmentList);
  const [isNew] = useState(!props.match.params || !props.match.params.id);

  useEffect(() => {
    props.reset();
    props.setPageNumber({ pageNumber: 1 });
  }, []);

  useEffect(() => {
    setdateFormat(props.setting.date_format);
  }, [props.setting.date_format]);

  useEffect(() => {
    setRecords(props.departmentList);
  }, [props.departmentList]);

  const handleSyncList = () => {
    props.reset();
    props.setPageNumber({ pageNumber: 1 });
  };

  const dateConverter = dateformat => {
    if (dateformat === null) {
      if (dateFormat === 1) {
        return 'DD-MM-YY';
      } else if (dateFormat === 2) {
        return 'DD-MM-YYYY';
      } else if (dateFormat === 3) {
        return 'MM/DD/YY';
      } else if (dateFormat === 4) {
        return 'MM/DD/YYYY';
      } else if (dateFormat === 5) {
        return 'DD.MM.YY';
      } else if (dateFormat === 6) {
        return 'DD.MM.YYYY';
      } else if (dateFormat === 7) {
        return 'YY/MM/DD';
      } else if (dateFormat === 8) {
        return 'YYYY/MM/DD';
      }
    } else {
      if (dateFormat === 1) {
        return moment(dateformat).format('DD-MM-YY');
      } else if (dateFormat === 2) {
        return moment(dateformat).format('DD-MM-YYYY');
      } else if (dateFormat === 3) {
        return moment(dateformat).format('MM/DD/YY');
      } else if (dateFormat === 4) {
        return moment(dateformat).format('MM/DD/YYYY');
      } else if (dateFormat === 5) {
        return moment(dateformat).format('DD.MM.YY');
      } else if (dateFormat === 6) {
        return moment(dateformat).format('DD.MM.YYYY');
      } else if (dateFormat === 7) {
        return moment(dateformat).format('YY/MM/DD');
      } else if (dateFormat === 8) {
        return moment(dateformat).format('YYYY/MM/DD');
      }
    }
  };

  const requestSearch = (searchedVal: string) => {
    const value = searchedVal.toLowerCase();
    const filteredRows = props.departmentList.filter(row => {
      return (
        (row.name !== null && row.name.toLowerCase().includes(value)) ||
        (row.created_at !== null && dateConverter(row.created_at).includes(value))
      );
    });
    setRecords(filteredRows);
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch(searched);
  };

  const jsonData = [];
  if (records.length > 0) {
    records.map(elem => {
      const data = { ...elem };
      jsonData.push(data);
    });
  }
  const { departmentList, match, loading } = props;
  return (
    <>
      <div className="entities-reports-main-container">
        {loading && <Spinner />}
        <div className="entities-reports-filter-area">
          <Grid container spacing={2} style={{ flexWrap: 'nowrap', width: '65%' }}>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <FormControl variant="outlined" size="small">
                <SearchBar
                  value={searched}
                  onChange={searchVal => requestSearch(searchVal)}
                  onCancelSearch={() => cancelSearch()}
                  placeholder="Search Here..."
                  style={{
                    outline: '1px solid #bbc4c2',
                    borderRadius: '4px',
                    height: '40px',
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container className="entities_reports_refresh_and_download_div" style={{ width: '35%' }}>
            <IconButton
              size="small"
              className="report-download-button"
              id="entities_reports_btn"
              onClick={() => deparmentConvertToPDF(jsonData)}
              disabled={props.loading}
            >
              <GetAppIcon fontSize="large" />
            </IconButton>

            <IconButton
              size="small"
              className="report-download-button"
              id="entities_reports_btn"
              disabled={props.loading}
              onClick={handleSyncList}
            >
              <AutorenewIcon fontSize="large" />
            </IconButton>

            {/* <Grid item xl={4} lg={4} md={4} sm={4} xs={4}> */}
            <Link
              to={`${match.url}/new`}
              className="btn jh-create-entity serviceAreaCreateButton incidentCreateButton"
              id="jh-create-entity"
              data-cy="entityCreateButton"
            >
              <FontAwesomeIcon icon="plus" />
              &nbsp; Create New
            </Link>
            {/* </Grid> */}
          </Grid>
        </div>

        <div>
          <div className="globalTableHeaderGradientColor departmentsTableHeaderRow sticky_div">
            <div className="departmentsTableHeaderCell departmentsHeaderDateCell">Date of Creation</div>
            <div className="departmentsTableHeaderCell departmentsHeaderNameCell">Department Name</div>
          </div>
          <InfiniteScroll
            dataLength={departmentList.length}
            scrollThreshold="200px"
            next={() => {
              if (departmentList.length > 0) {
                props.setPageNumber({ pageNumber: props.pageId['pageNumber'] + 1 });
              }
            }}
            hasMore={true}
            loader={''}
          >
            <TableContainer component={Paper}>
              <Table>
                <tbody>
                  {records && records.length > 0
                    ? records.map((department, i) => (
                        <tr key={`entity-${i}`} data-cy="entityTable" className="departmentTableBodyRows">
                          <td className="departmentTableBodyCell departmentTableBodyDatecell">{dateConverter(department.created_at)}</td>
                          <td className="departmentTableBodyCell departmentTableBodyNamecell">
                            <a href={`${match.url}/${department.id}/edit`}>{department.name}</a>
                          </td>
                        </tr>
                      ))
                    : !loading && <div className="no-record-warning">No Departments found</div>}
                </tbody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  departmentList: storeState.department.entities,
  loading: storeState.department.loading,
  setting: storeState.PersonalSettings.storedPersonalSettings,
  pageId: storeState.department.pageNumber,
});

const mapDispatchToProps = {
  getEntities,
  reset,
  setPageNumber,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Department);
