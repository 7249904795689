import React, { useState, useEffect } from 'react';
import { Button, IconButton } from '@material-ui/core';
import { IServiceArea } from 'app/shared/model/service-area.model';
import Switch from '@material-ui/core/Switch';
import Tip from 'app/commonUI/tip';
import GoogleMapsComponent from './map';
import MenuIcon from '@material-ui/icons/Menu';
import Checkpoint from './checkpoint';
import Popper from '@material-ui/core/Popper';
import QRCode from 'react-qr-code';
import CloseIcon from '@material-ui/icons/Close';
import { Divider } from '@material-ui/core';
import { PATROL_CHECK_POINT_HEADING } from 'app/config/constants';

interface ServiceAreaPatrolSettingsProps {
  clientsList?: Array<any> | any;
  handleClientData?: (data: any, event: any) => void;
  client?: { id: number; text: string } | any;
  fieldError?: Record<string, any>;
  designations?: Array<any> | any;
  handlePositionData?: (data: any, event: any) => void;
  values?: IServiceArea | any;
  serviceAreaEntity?: IServiceArea | any;
  setValues?: (data: any) => void;
  isNew?: boolean;
}

const ServiceAreaPatrolSettings: React.FC<ServiceAreaPatrolSettingsProps> = props => {
  const [checkPointsAllowed, setCheckPointsAllowed] = useState({
    ['checkPointsAllowed']: false,
    ['mapAllowed']: false,
    ['patrolRequired']: false,
  });
  const [center, setCenter] = useState({ lat: 51.509865, lng: -0.118092 });
  const [coordinates, setCoordinates] = React.useState([]);
  const [markerCount, setMarkerCount] = React.useState(1);
  const dragItem = React.useRef<any>(null);
  const dragOverItem = React.useRef<any>(null);
  const [checkpointsList, setCheckpointsList] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopperIndex, setOpenPopperIndex] = useState(null);

  useEffect(() => {
    if (props.values && props.values['checkpoints'] === true) {
      setCheckPointsAllowed(oldValues => ({ ...oldValues, ['checkPointsAllowed']: true }));
      setCheckPointsAllowed(oldValues => ({ ...oldValues, ['patrolRequired']: true }));
      populateCheckpoints();
    }
    if (props.values && props.values['map'] === true) {
      setCheckPointsAllowed(oldValues => ({ ...oldValues, ['mapAllowed']: true }));
      setCheckPointsAllowed(oldValues => ({ ...oldValues, ['patrolRequired']: true }));
      populateMap();
    }
  }, [props.serviceAreaEntity]);

  useEffect(() => {
    if (!props.isNew) {
      if (props.values && props.values['map'] === true) {
        if (props.values['checkpointData']) {
          setCenter({ lat: props.values['checkpointData'][0]?.latitude, lng: props.values['checkpointData'][0]?.longitude });
        }
      }
    }
  }, [props.serviceAreaEntity]);

  useEffect(() => {
    updateValuesForCheckpoints();
  }, [checkpointsList]);

  const handleChange = event => {
    setCheckPointsAllowed(oldValues => ({ ...oldValues, ['patrolRequired']: event.target.checked }));
    props.setValues(oldValues => ({ ...oldValues, ['checkpoints']: event.target.checked }));
    if (event.target.checked === false && checkPointsAllowed['mapAllowed'] === true) {
      setCheckPointsAllowed(oldValues => ({ ...oldValues, ['mapAllowed']: !checkPointsAllowed['mapAllowed'] }));
      props.setValues(oldValues => ({ ...oldValues, ['map']: !checkPointsAllowed['mapAllowed'] }));
    }
    if (event.target.checked === false) {
      setCoordinates([]);
      setCheckpointsList([]);
      setMarkerCount(1);
    }
  };

  const handleMapChange = event => {
    props.setValues(oldValues => ({ ...oldValues, ['map']: event.target.checked }));
    props.setValues(oldValues => ({ ...oldValues, ['checkpoints']: false }));
    deleteMarks();
    setCheckPointsAllowed(oldValues => ({ ...oldValues, ['mapAllowed']: event.target.checked }));
    setCheckPointsAllowed(oldValues => ({ ...oldValues, ['checkPointsAllowed']: false }));
  };

  const deleteMarks = () => {
    setCoordinates([]);
    setCheckpointsList([]);
    setMarkerCount(1);
  };

  const addCheckpoint = () => {
    props.setValues(oldValues => ({ ...oldValues, ['checkpoints']: true }));
    setCheckPointsAllowed(oldValues => ({ ...oldValues, ['checkPointsAllowed']: true }));
    setCheckpointsList([...checkpointsList, { id: new Date().getTime(), value: '', latitude: '', longitude: '', sequence: '' }]);
    setCheckPointsAllowed(oldValues => ({ ...oldValues, ['mapAllowed']: false }));
  };

  const handleSort = () => {
    const _checkpointsList = [...checkpointsList];

    const draggedItemContent = _checkpointsList.splice(dragItem.current, 1)[0];

    _checkpointsList.splice(dragOverItem.current, 0, draggedItemContent);

    props.setValues(oldValues => ({ ...oldValues, checkpointData: _checkpointsList }));
    dragItem.current = null;
    dragOverItem.current = null;
    _checkpointsList.map((item, index) => (item.sequence = index + 1));
    setCheckpointsList(_checkpointsList);
  };

  const handleCheckpoint = (event, id, index) => {
    const record = checkpointsList.find(el => el.id === id);
    record.value = event.target.value;
    record.sequence = index + 1;

    updateValuesForCheckpoints();
  };

  const updateValuesForCheckpoints = () => {
    const checkpointsData = [];
    checkpointsList.forEach((element, idx) => {
      checkpointsData[idx] = {
        id: element.id ? element.id : null,
        value: element.value ? element.value : null,
        latitude: element.latitude ? element.latitude : null,
        longitude: element.longitude ? element.longitude : null,
        sequence: element.sequence ? element.sequence : null,
      };
    });

    props.setValues(oldValues => ({ ...oldValues, checkpointData: checkpointsData }));
    props.setValues(oldValues => ({ ...oldValues, checkpointName: checkpointsData }));
  };

  const handleCheckPointDelete = (key, index) => {
    const data = props.values['checkpointData'];


    const updatedCheckPoints = checkpointsList.filter(CP => {
      return CP.id !== key;
    });

    setCheckpointsList(updatedCheckPoints);

    if (updatedCheckPoints.length === 0) {
      setCheckPointsAllowed(oldValues => ({ ...oldValues, ['checkPointsAllowed']: false }));
      props.setValues(oldValues => ({ ...oldValues, checkpoints: false }));
    }

    const _data = updatedCheckPoints;
    const cp = [];

    _data &&
      _data.map((item, index) => {
        cp.push({
          id: item.id ? item.id : null,
          value: item.value ? item.value : null,
          latitude: item.latitude ? item.latitude : null,
          longitude: item.longitude ? item.longitude : null,
          sequence: index + 1,
        });
      });
    setCheckpointsList(cp);
    updateValuesForCheckpoints();
  };

  const populateCheckpoints = () => {
    const data = props.values['checkpointData'];
    const cp = [];

    data &&
      data.map((key, index) => {
        cp.push({
          id: data[index].id,
          value: data[index].value,
          latitude: data[index].latitude ? data[index].latitude : null,
          longitude: data[index].longitude ? data[index].longitude : null,
          sequence: index + 1,
        });
      });
    setCheckpointsList(cp);
    updateValuesForCheckpoints();
  };

  const populateMap = () => {
    let data = [];
    const cp = [];
    const marks = [];

    let latitude;
    let longitude;
    let id;
    let value;
    let lat;
    let lng;
    data = props.values['checkpointData'];

    data &&
      data.map((key, index) => {
        id = data[index].id ? data[index].id : null;
        (value = data[index].value), (latitude = data[index].latitude ? data[index].latitude : null);
        longitude = data[index].longitude ? data[index].longitude : null;
        cp.push({
          latitude,
          longitude,
          id,
          value,
        });
      });

    data &&
      data.map((key, index) => {
        lat = data[index].latitude ? data[index].latitude : null;
        lng = data[index].longitude ? data[index].longitude : null;
        marks.push({
          lat,
          lng,
        });
      });

    setCoordinates(marks);
    setCheckpointsList(cp);
  };

  const setMark = coord => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setCoordinates([
      ...coordinates,
      {
        lat,
        lng,
      },
    ]);
    setMarkerCount(markerCount + 1);
    addCheckpointMap(lat, lng, markerCount);
  };

  const setMarkOnEnteringAddress = (lat, lng) => {
    setCoordinates([...coordinates, { lat, lng }]);
    setMarkerCount(markerCount + 1);
    addCheckpointMap(lat, lng, markerCount);
  };

  const addCheckpointMap = (lat, lng, seq) => {
    setCheckpointsList([...checkpointsList, { id: new Date().getTime(), value: '', latitude: lat, longitude: lng, sequence: seq }]);
  };

  const handleQRcode = (index, event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpenPopperIndex(index);
  };

  const handleQRPopperClose = () => {
    setAnchorEl(null);
    setOpenPopperIndex(null);
  };

  const open = Boolean(anchorEl);

  const QrCodeDownload = (index: number, checkpointName: string) => {
    const svgElement = document.getElementById(`qr-gen` + index);
    const svgString = svgElement.outerHTML;
    const svgWithBackground = `
      <svg width="256" height="256" xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="100%" fill="white" />
      ${svgString}
      </svg>
    `;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width + 10;
      canvas.height = img.height + 10;
      context.fillStyle = 'white';
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(img, 5, 5);
      const dataUrl = canvas.toDataURL();
      const downloadLink = document.createElement('a');
      downloadLink.href = dataUrl;
      downloadLink.download = `${checkpointName}_QRcode.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };

    img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgWithBackground)));
  };

  return (
    <div className="patrol-execution-main-container">
      <div className="patrol-settings-switch-button-divs">
        <div className="patrol-settings-patrol-button-div">
          <div className="switch-div-text">
            <span className="patrol-switch-heading" style={{ color: '#7A55DE', fontSize: '22px' }}>
              Shift Patrols
              <Tip message="1 You can create patrol check points here, no. of patrols for a shift can be set during shift creation." />
            </span>
          </div>

          <div className="switch-div-icon">
            <Switch
              color="primary"
              name="outbound_reminders"
              inputProps={{ 'aria-label': 'primary checkbox' }}
              onChange={() => {
                handleChange(event);
              }}
              checked={checkPointsAllowed['patrolRequired'] === true ? true : false}
            />
          </div>
        </div>
        <Divider style={{ margin: '3% 0' }} />

        {checkPointsAllowed['patrolRequired'] && (
          <>
            <div className="patrol-settings-map-button-div">
              <div className="switch-div-text">
                <span style={{ color: '#7A55DE', fontSize: '22px' }}>
                  Use Maps
                  <Tip message="You can create patrol checkpoints using the map." />
                </span>
              </div>

              <div className="switch-div-icon">
                <Switch
                  color="primary"
                  name="outbound_reminders"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  onChange={() => {
                    handleMapChange(event);
                  }}
                  checked={props.values && props.values['map']}
                />
              </div>
            </div>
            <Divider style={{ margin: '3% 0' }} />
          </>
        )}
      </div>

      {checkPointsAllowed['patrolRequired'] === true ? (
        <>
          {checkPointsAllowed['mapAllowed'] === false ? (
            <div className="manual-checkpoints-div">
              <div className="add-checkpoints-text" style={{ display: 'flex' }}>
                {checkpointsList && checkpointsList.length > 0 ? (
                  <div style={{ marginLeft: 'auto', marginRight: '0' }}>
                    <Button
                      className="add-new-checkpoints-button"
                      onClick={addCheckpoint}
                      style={{ width: '100px', background: '#D48BD3', color: 'white' }}
                    >
                      {' '}
                      Add
                    </Button>
                  </div>
                ) : null}
              </div>

              {checkpointsList && checkpointsList.length === 0 ? (
                <div className="add-checkpoints-text">
                  <span>
                    {' '}
                    Please{' '}
                    <Button className="add-checkpoints-button" onClick={addCheckpoint}>
                      {' '}
                      Add
                    </Button>{' '}
                    Checkpoints
                  </span>
                </div>
              ) : null}
            </div>
          ) : null}

          <div className="list-container">
            {checkpointsList && checkpointsList.length > 0 && (
              <>
                <span style={{ color: '#7A55DE' }}>{PATROL_CHECK_POINT_HEADING}</span>
                {checkpointsList.map((item, index) => (
                  <>
                    {checkPointsAllowed['checkPointsAllowed'] === true ? (
                      <div
                        key={index}
                        className="list-item"
                        draggable
                        onDragStart={e => (dragItem.current = index)}
                        onDragEnter={e => (dragOverItem.current = index)}
                        onDragEnd={handleSort}
                        onDragOver={e => e.preventDefault()}
                      >
                        <MenuIcon className="service_area_checkpoint_menu_icon" />
                        <span className="checkpoint-sequence-serial">{index + 1}. </span>

                        <Checkpoint
                          key={item.id}
                          nameId={index + 1}
                          checkpointId={item.id}
                          handleCheckpoint={handleCheckpoint}
                          handleCheckPointDelete={handleCheckPointDelete}
                          value={item.value}
                          latitude={item.latitude}
                          longitude={item.longitude}
                          index={index}
                          mapValues={props.values}
                        />

                        <IconButton
                          onClick={event => {
                            handleQRcode(index, event);
                          }}
                          className="service_area_checkpoint_delete_from_list_icon"
                          size="small"
                          style={{ margin: '1%' }}
                          data-cy="entityEditButton"
                        >
                          <img src="content/images/qrcode.png" />
                        </IconButton>

                        <Popper open={openPopperIndex === index && open} anchorEl={anchorEl} className="service-area-qr-code-popper">
                          <div style={{ height: 'auto', margin: '0 auto', width: '100%' }}>
                            <div style={{ width: '100%' }}>
                              <div style={{ width: '10%', marginLeft: 'auto' }}>
                                <CloseIcon onClick={handleQRPopperClose}></CloseIcon>
                              </div>
                            </div>
                            <div style={{ width: '55%', marginLeft: '20%', marginTop: '10%', marginBottom: '10%' }}>
                              <QRCode
                                id={'qr-gen' + index}
                                size={256}
                                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                                value={item.value}
                                viewBox={`0 0 256 256`}
                              />
                            </div>
                            <span style={{ color: '#7A55DE', fontSize: '22px', marginLeft: '45px' }}>
                              {' '}
                              <Button
                                variant="contained"
                                color="secondary"
                                style={{ background: '#D48BD3', textDecoration: 'none', color: 'white', width: '150px' }}
                                onClick={() => QrCodeDownload(index, item.value)}
                              >
                                Download
                              </Button>
                            </span>
                          </div>
                        </Popper>

                        <IconButton
                          className="service_area_checkpoint_delete_from_list_icon"
                          onClick={() => {
                            handleCheckPointDelete(item.id, index);
                          }}
                        >
                          <img style={{ width: '100%', height: '10%' }} src="content/images/Trash4.png" />
                        </IconButton>
                      </div>
                    ) : null}

                    {checkPointsAllowed['mapAllowed'] === true ? (
                      <div key={index} className="list-item">
                        <span className="checkpoint-sequence-serial">{index + 1}. </span>
                        <Checkpoint
                          key={item.id}
                          nameId={index + 1}
                          checkpointId={item.id}
                          handleCheckpoint={handleCheckpoint}
                          handleCheckPointDelete={handleCheckPointDelete}
                          value={item.value}
                          latitude={item.latitude}
                          longitude={item.longitude}
                          index={index}
                          mapValues={props.values}
                        />

                        <IconButton
                          onClick={event => {
                            handleQRcode(index, event);
                          }}
                          className="service_area_checkpoint_delete_from_list_icon"
                          size="small"
                          style={{ margin: '1%' }}
                          data-cy="entityEditButton"
                        >
                          <img src="content/images/qrcode.png" />
                        </IconButton>

                        <Popper open={openPopperIndex === index && open} anchorEl={anchorEl} className="service-area-qr-code-popper">
                          <div style={{ height: 'auto', margin: '0 auto', width: '100%' }}>
                            <div style={{ width: '100%' }}>
                              <div style={{ width: '10%', marginLeft: 'auto' }}>
                                <CloseIcon onClick={handleQRPopperClose}></CloseIcon>
                              </div>
                            </div>

                            <div style={{ width: '55%', marginLeft: '20%', marginTop: '10%', marginBottom: '10%' }}>
                              <QRCode
                                id={'qr-gen' + index}
                                size={256}
                                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                                value={` ${item.value},${item.latitude},${item.longitude}`}
                                viewBox={`0 0 256 256`}
                              />
                            </div>
                            <span style={{ color: '#7A55DE', fontSize: '22px', marginLeft: '45px' }}>
                              {' '}
                              <Button
                                variant="contained"
                                color="secondary"
                                style={{ background: '#D48BD3', textDecoration: 'none', color: 'white', width: '150px' }}
                                onClick={() => QrCodeDownload(index, item.value)}
                              >
                                Download
                              </Button>
                            </span>
                          </div>
                        </Popper>
                      </div>
                    ) : null}
                  </>
                ))}
              </>
            )}
          </div>
        </>
      ) : null}

      {checkPointsAllowed['mapAllowed'] === true ? (
        <GoogleMapsComponent
          center={center}
          setCenter={setCenter}
          setMark={setMark}
          setMarkOnEnteringAddress={setMarkOnEnteringAddress}
          coordinates={coordinates}
          deleteMarks={deleteMarks}
        />
      ) : null}
    </div>
  );
};
export default ServiceAreaPatrolSettings;
