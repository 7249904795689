/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { getEntity, updateEntity } from '../person.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import {
  Divider,
  Grid,
  Paper,
  Button,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import '../person.scss';
import MomentUtils from '@date-io/moment';
import CustomToolbarComponent from '../../service-area/CustomToolbarComponent';
import { getDateFormat } from 'app/shared/util/calender-utils';
import moment from 'moment';
import PhoneInput from 'react-phone-input-2';
import { Autocomplete } from '@material-ui/lab';
import country_types from '../../../shared/data/country_types.json';
import { getCity, getCountry, reset as resetCountry } from '../../sub-contractor/subcontractor.reducer';
import { validationMessageBeforeSubmit, validationMessageOnChange } from '../../../../utils/validationMessages';
import { useLocation, useHistory } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import { DeleteModal } from './delete-modal';
import { deleteEntity, reset, getAllCountry, createEntity } from '../../../entities/person/person.reducer';
import country from 'app/entities/country/country';
import city from 'app/entities/city/city';
import PersonCompanyInfo from './person-company-info';
import DateFnsUtils from '@date-io/date-fns';

export interface IPersonDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ProfileDetails = props => {
  const [imageFile, setImageFile] = useState(null);
  const [fieldValues, setFieldValues] = useState(!props.match.params || !props.match.params.id ? props.profileData : props.personEntity);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showEditPage, setShowEditPage] = useState(!props.match.params || !props.match.params.id ? true : false);
  const [selectRdoBtn, setSelectRdoBtn] = useState(false);
  const [deleteModalSchedule, setDeleteModalSchedule] = useState(false);
  const [shiftId, setShiftId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [values, setValues] = useState({
    pageNumber: 1,
  });
  const [fieldError, setFieldError] = useState({
    first_name_error: false,
    last_name_error: false,
    email_error: false,
    email_helper: '',
    phoneNumber_error: false,
    phoneNumber_helper: '',
    address_error: false,
    address_helper: '',
    identification_number_error: false,
    identification_number_helper: '',
  });
  const [showMalaysiaFields, setShowMalaysiaFields] = useState(false);
  const [showPakistanFields, setShowPakistanFields] = useState(false);
  const [showUKFields, setShowUKFields] = useState(false);
  const location = useLocation();
  const [kinRelation, setKinRelation] = useState(null);
  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const [date_of_birth, setDateOfBirth] = useState(null);
  const { personEntity } = props;
  const [personFiles, setPersonFiles] = useState([]);
  const [visaRequired, setVisaRequired] = useState();
  const [showDivs, setShowDivs] = useState({ visaRequired: false });

  useEffect(() => {
    if (isNew) {
      // props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
    props.resetCountry();
  }, []);

  useEffect(() => {
    if (isNew) {
      props.setProfileData(fieldValues);
    }
  }, [fieldValues]);

  useEffect(() => {
    if (!isNew) {
      setFieldValues(props.personEntity);
      if (props.personEntity['country'] && props.personEntity['country']['name'] === 'Malaysia') {
        setShowMalaysiaFields(true);
      } else if (props.personEntity['country'] && props.personEntity['country']['name'] === 'Pakistan') {
        setShowPakistanFields(true);
      } else {
        setShowUKFields(true);
      }
    }
  }, [props.personEntity]);

  useEffect(() => {
    if (!isNew) {
      if (props.personEntity.imageUrl && props.personEntity.imageUrl !== null) {
        setImageFile(props.personEntity.imageUrl);
      } else {
        setImageFile('content/images/profileImage.png');
      }
    } else {
      if (props.profileData.person_image && props.profileData.person_image !== null) {
        setImageFile(URL.createObjectURL(props.profileData.person_image));
      } else {
        setImageFile('content/images/profileImage.png');
      }
    }
  }, [props.personEntity.imageUrl]);

  useEffect(() => {
    props.getCountry();
    props.getAllCountry();
  }, []);

  useEffect(() => {
    if (props.checkValidation) {
      checkForRequiredValidations();
    }
  }, [props]);

  const setNewValues = e => {
    setFieldValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));

    if (
      e.target.name === 'first_name' &&
      (!/^(?! )[a-zA-Z0-9\s(),.-]*$/.test(e.target.value) || e.target.value === '' || e.target.value.length < 1)
    ) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({
        ...oldFieldError,
        [e.target.name + '_helper']: validationMessageOnChange.firstName,
      }));
    } else if (
      e.target.name === 'last_name' &&
      (!/^(?! )[a-zA-Z0-9\s(),.-]*$/.test(e.target.value) || e.target.value === '' || e.target.value.length < 1)
    ) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({
        ...oldFieldError,
        [e.target.name + '_helper']: validationMessageOnChange.lastName,
      }));
    } else if (
      e.target.name === 'email' &&
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        e.target.value
      )
    ) {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_helper']: validationMessageOnChange.email }));
    } else {
      setFieldError(oldFieldError => ({ ...oldFieldError, [e.target.name + '_error']: false, [e.target.name + '_helper']: '' }));
    }
  };

  const handlePhoneNumber = phone => {
    setFieldError(oldFieldValues => ({ ...oldFieldValues, ['phoneNumber_error']: false, ['phoneNumber_helper']: '' }));
    setPhoneNumber(phone);
    if (isNew) {
      setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['phoneNumber']: phone,
      }));
    } else {
      setFieldValues(oldFieldValues => ({ ...oldFieldValues, ['phoneNumber']: { value: phone } }));
    }
  };

  const handleIncomeTaxNumber = event => {
    if (!props.isNew) {
      if (props.personEntity.income_tax_number) {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['income_tax_number']: {
            id: props.personEntity.income_tax_number.id,
            value: event.target.value,
            designation_qualification_type_id: props.personEntity.income_tax_number.designation_qualification_type_id,
            person_id: props.personEntity.id,
          },
        }));
      } else {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['income_tax_number']: {
            id: null,
            value: event.target.value,
            designation_qualification_type_id: props.personEntity['income_tax_number_type']
              ? props.personEntity['income_tax_number_type']['id']
              : '',
            person_id: props.personEntity.id,
          },
        }));
      }
    }
  };
  const handlePassportNumber = event => {
    if (!props.isNew) {
      if (props.personEntity.passport_number) {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['passport_number']: {
            id: props.personEntity.passport_number.id,
            value: event.target.value,
            designation_qualification_type_id: props.personEntity.passport_number.designation_qualification_type_id,
            person_id: props.personEntity.id,
          },
        }));
      } else {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['passport_number']: {
            id: null,
            value: event.target.value,
            designation_qualification_type_id: props.personEntity['passport_number_type']
              ? props.personEntity['passport_number_type']['id']
              : '',
            person_id: props.personEntity.id,
          },
        }));
      }
    }
  };
  const handleKwspNumber = event => {
    if (!props.isNew) {
      if (props.personEntity.kwsp_number) {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['kwsp_number']: {
            id: props.personEntity.kwsp_number.id,
            value: event.target.value,
            designation_qualification_type_id: props.personEntity.kwsp_number.designation_qualification_type_id,
            person_id: props.personEntity.id,
          },
        }));
      } else {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['kwsp_number']: {
            id: null,
            value: event.target.value,
            designation_qualification_type_id: props.personEntity['kwsp_number_type'] ? props.personEntity['kwsp_number_type']['id'] : '',
            person_id: props.personEntity.id,
          },
        }));
      }
    }
  };

  const handleKinRelation = (_, e) => {
    if (e) {
      setFieldValues(oldValues => ({ ...oldValues, ['kin_relation']: e }));
    } else {
      setFieldValues(oldValues => ({ ...oldValues, ['kin_relation']: null }));
    }
  };

  const handleKinFirstName = e => {
    if (e) {
      setFieldValues(oldValues => ({ ...oldValues, ['kin_first_name']: e.target.value }));
    } else {
      setFieldValues(oldValues => ({ ...oldValues, ['kin_first_name']: null }));
    }
  };

  const handleKinLastName = e => {
    if (e) {
      setFieldValues(oldValues => ({ ...oldValues, ['kin_last_name']: e.target.value }));
    } else {
      setFieldValues(oldValues => ({ ...oldValues, ['kin_last_name']: null }));
    }
  };

  const setFormValues = event => {
    setFieldValues(oldFieldValues => ({ ...oldFieldValues, [event.target.name]: event.target.value }));
    setFieldError(oldFieldError => ({ ...oldFieldError, [event.target.name + '_error']: false }));
    setFieldError(oldFieldError => ({ ...oldFieldError, [event.target.name + '_helper']: '' }));
  };

  const genderhandleChange = event => {
    setFieldValues(oldFieldValues => ({ ...oldFieldValues, ['gender']: event.target.value }));
  };

  const handleAddress = e => {
    if (isNew) {
      setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['address']: e.target.value,
      }));
    } else {
      if (personEntity.address && Object.keys(personEntity.address).length !== 0) {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['address']: {
            id: personEntity ? (personEntity.address ? personEntity.address[0].id : null) : null,
            address_line_one: e.target.value,
            address_line_two: null,
            address_unit_identifier_value: null,
          },
        }));
      } else {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['address']: { id: null, address_line_one: e.target.value },
        }));
      }
    }
    setFieldError(oldFieldError => ({ ...oldFieldError, ['address_error']: false }));
    setFieldError(oldFieldError => ({ ...oldFieldError, ['address_helper']: '' }));
  };

  const handleCountryData = (_, e) => {
    if (e) {
      setFieldValues(oldValues => ({ ...oldValues, ['country']: e }));
      props.getCity(e.id);
      if (e.name === 'Malaysia') {
        setShowMalaysiaFields(true);
      } else {
        setFieldValues(oldValues => ({ ...oldValues, ['income_tax_number']: null }));
        setFieldValues(oldValues => ({ ...oldValues, ['passport_number']: null }));
        setFieldValues(oldValues => ({ ...oldValues, ['kwsp_number']: null }));
        setShowMalaysiaFields(false);
      }
    } else {
      setFieldValues(oldValues => ({ ...oldValues, ['country']: null }));
    }
  };

  const handleCityData = (_, e) => {
    if (e) {
      setFieldValues(oldValues => ({ ...oldValues, ['city']: e }));
    } else {
      setFieldValues(oldValues => ({ ...oldValues, ['city']: null }));
    }
  };

  const handleBirthCountryData = (_, e) => {
    if (e) {
      setFieldValues(oldValues => ({ ...oldValues, ['birthCountry']: e }));
      props.getCity(e.id);
    } else {
      setFieldValues(oldValues => ({ ...oldValues, ['birthCountry']: null }));
    }
  };

  const handleNationalitiesData = (_, e) => {
    if (e) {
      setFieldValues(oldValues => ({ ...oldValues, ['nationality']: e }));
    } else {
      setFieldValues(oldValues => ({ ...oldValues, ['nationality']: null }));
    }
  };

  const handleProfilePicture = e => {
    setFieldValues(oldFieldValues => ({ ...oldFieldValues, ['person_image']: e.target.files[0] }));
    if (e.target.files && e.target.files[0]) {
      setImageFile(URL.createObjectURL(e.target.files[0]));
    }
  };

  const checkForRequiredValidations = () => {
    let output = true;
    if (fieldValues['first_name'] === undefined || fieldValues['first_name'] === '') {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['first_name_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, ['first_name_helper']: validationMessageBeforeSubmit.firstName }));
      output = false;
    }
    if (fieldValues['address'] === undefined || fieldValues['address'] === '') {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['address_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, ['address_helper']: validationMessageBeforeSubmit.address }));
      output = false;
    }
    if (fieldValues['last_name'] === undefined || fieldValues['last_name'] === '') {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['last_name_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, ['last_name_helper']: validationMessageBeforeSubmit.lastName }));
      output = false;
    }
    if (fieldValues['email'] === undefined || fieldValues['email'] === '') {
      setFieldError(oldFieldError => ({ ...oldFieldError, ['email_error']: true }));
      setFieldError(oldFieldError => ({ ...oldFieldError, ['email_helper']: validationMessageBeforeSubmit.email }));
      output = false;
    }
    if (
      fieldValues['phoneNumber'] === undefined ||
      Object.keys(fieldValues['phoneNumber']).length === 0 ||
      fieldValues['phoneNumber'] === ''
    ) {
      setFieldError(oldFieldError => ({
        ...oldFieldError,
        ['phoneNumber_error']: true,
        ['phoneNumber_helper']: 'Please enter phone number',
      }));
      output = false;
    }

    if (checkForValidations()) {
      props.setIsProfileFilled(true);
    }
    return output;
  };

  const checkForValidations = () => {
    const result =
      !fieldError['first_name_error'] &&
      !fieldError['last_name_error'] &&
      !fieldError['email_error'] &&
      !fieldError['address_error'] &&
      !fieldError['identification_number_error'] &&
      fieldValues['phoneNumber'] !== undefined &&
      fieldValues['first_name'] !== undefined &&
      fieldValues['last_name'] !== undefined &&
      fieldValues['email'] !== undefined &&
      fieldValues['address'] !== undefined;

    return result;
  };

  const saveEntity = event => {
    event.preventDefault();
    const formData = new FormData();
    if (fieldValues['person_image'] !== undefined) {
      formData.append('avatar', fieldValues['person_image']);
    }
    const object = new FormData();
    if (personFiles.length > 0) {
      personFiles.forEach((element, index) => {
        object.append(`avatar-${index}`, element.file);
      });
      object.append('doc_type', '6');
    }
    if (isNew && checkForRequiredValidations() && checkForValidations()) {
      props.setProfileData(fieldValues);
      props.setPage(10);
    } else if (!isNew && checkForRequiredValidations() && checkForValidations()) {
      props.updateEntity(fieldValues, formData, object);
      setShowEditPage(false);
      props.getEntity(props.match.params.id);
    } else {
      checkForRequiredValidations();
    }
  };

  const handleDrawerMenu = () => {
    props.setPage(10);
  };

  const profilePageHandler = () => {
    setFieldValues(props.personEntity);
    setImageFile(props.personEntity.imageUrl);
    setShowEditPage(false);
  };

  const editPageHandler = () => {
    setShowEditPage(true);
  };

  const deleteStaff = () => {
    props.deleteEntity(props.personEntity.id);
    setShowDeleteModal(true);
  };

  const showModalHandler = () => {
    setDeleteModalSchedule(true);
  };

  const handleCNICExpiryDate = e => {
    if (!props.isNew) {
      if (props.personEntity.CNIC) {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['CNIC']: {
            id: props.personEntity?.CNIC?.id,
            value: props.personEntity.CNIC ? props.personEntity.CNIC.value : null,
            expiry_date: new Date(e),
            name: 'CNIC',
            designation_qualification_type_id: 14,
            person_id: props.personEntity.id,
          },
        }));
      } else {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['CNIC']: {
            id: null,
            value: fieldValues && fieldValues['CNIC'] && fieldValues['CNIC']['value'] ? fieldValues['CNIC']['value'] : null,
            expiry_date: new Date(e),
            name: 'CNIC',
            designation_qualification_type_id: 14,
            person_id: props.personEntity.id,
          },
        }));
      }
    } else {
      setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['CNIC']: {
          value: fieldValues && fieldValues['CNIC'] && fieldValues['CNIC']['value'] ? fieldValues['CNIC']['value'] : null,
          expiry_date: new Date(e),
          name: 'CNIC',
          designation_qualification_type_id: 14,
        },
      }));
    }
  };

  const handleRightToWorkExpiry = e => {
    setFieldValues(oldFieldValues => ({ ...oldFieldValues, right_to_work_expiry: new Date(e) }));
  };

  const handleCNICNumber = e => {
    if (!props.isNew) {
      if (props.personEntity.CNIC) {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['CNIC']: {
            id: props.personEntity?.CNIC?.id,
            value: e.target.value,
            expiry_date:
              fieldValues && fieldValues['CNIC'] && fieldValues['CNIC']['expiry_date'] ? fieldValues['CNIC']['expiry_date'] : null,
            name: 'CNIC',
            designation_qualification_type_id: 14,
            person_id: props.personEntity.id,
          },
        }));
      } else {
        setFieldValues(oldFieldValues => ({
          ...oldFieldValues,
          ['CNIC']: {
            id: null,
            value: e.target.value,
            expiry_date:
              fieldValues && fieldValues['CNIC'] && fieldValues['CNIC']['expiry_date'] ? fieldValues['CNIC']['expiry_date'] : null,
            name: 'CNIC',
            designation_qualification_type_id: 14,
            person_id: props.personEntity.id,
          },
        }));
      }
    } else {
      setFieldValues(oldFieldValues => ({
        ...oldFieldValues,
        ['CNIC']: {
          value: e.target.value,
          name: 'CNIC',
          designation_qualification_type_id: 14,
          expiry_date: fieldValues && fieldValues['CNIC'] && fieldValues['CNIC']['expiry_date'] ? fieldValues['CNIC']['expiry_date'] : null,
        },
      }));
    }
  };

  const handleDateOfBirth = e => {
    const d = moment(e).format('YYYY-MM-DD');
    setDateOfBirth(e);
    setFieldValues(oldValues => ({ ...oldValues, ['date_of_birth']: d }));
  };

  const handleEmail = e => {
    const emailValue = e.target.value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    setFieldError(oldFieldError => ({
      ...oldFieldError,
      email_error: !emailRegex.test(emailValue),
      email_helper: !emailRegex.test(emailValue) ? 'Invalid email format' : '',
    }));

    setFieldValues(oldFieldValues => ({
      ...oldFieldValues,
      email: emailValue,
    }));
  };

  const visaRequiredhandleChange = (_, e) => {
    if (e) {
      setFieldValues(oldFieldValues => ({ ...oldFieldValues, ['visa_required']: e }));
    } else {
      setFieldValues(oldFieldValues => ({ ...oldFieldValues, ['visa_required']: null }));
    }
  };

  const handleHideShowDiv = (name, value) => {
    setShowDivs(oldValues => ({ ...oldValues, [name]: value }));
  };

  return (
    <div className="person-view-main-container">
      <Grid className="person-general-details">
        {showEditPage === false ? (
          <div className="PersonProfileEditBtnContainer">
            <Button
              variant="contained"
              className="Profile_Detials_edit_btn"
              onClick={editPageHandler}
              startIcon={<img src="content/icons/Personal Details/Edit.png" />}
            >
              Edit
            </Button>

            <Button
              variant="contained"
              className="Profile_Detial_del_btn"
              onClick={showModalHandler}
              startIcon={<img src="content/icons/Personal Details/trash-icon.png" />}
            >
              Delete Profile
            </Button>
          </div>
        ) : (
          ''
        )}

        {showEditPage === false ? <Divider style={{ margin: '4% 0' }}></Divider> : null}
        <div className="person-view-profile-details">
          <div className="person-view-profile-details-logo-div">
            <div style={{ position: 'relative', width: '14vw', height: '26vh', borderRadius: '4px' }}>
              <img
                src={imageFile ? imageFile : null}
                className="person-logo"
                style={{ width: '100%', height: '100%', borderRadius: '4px' }}
                alt="Person Logo"
              />
              <input type="file" id="file-input" name="person_image" onChange={handleProfilePicture} style={{ display: 'none' }} />
              {showEditPage === true ? (
                <label htmlFor="file-input" style={{ position: 'absolute', top: 0, right: 0 }}>
                  <img src="content/icons/Personal Details/Edit.svg" className="edit-icon" style={{ cursor: 'pointer' }} alt="Edit Icon" />
                </label>
              ) : null}
            </div>
          </div>

          <Grid container spacing={2} id="general-profile-info">
            <Grid item xs={12} md={12} lg={12} style={{ paddingLeft: '17px' }}>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                className="personRadioBtns"
                style={{ display: 'flex', flexDirection: 'row' }}
                value={fieldValues['gender'] ? fieldValues['gender'] : null}
                onChange={genderhandleChange}
              >
                <FormControlLabel
                  value="male"
                  control={<Radio style={{ color: '#4620B7' }} />}
                  label="Male"
                  disabled={showEditPage === false ? true : false}
                  className="selected_gender_rdobtn"
                />

                <FormControlLabel
                  value="female"
                  control={<Radio style={{ color: '#4620B7' }} />}
                  label="Female"
                  disabled={showEditPage === false ? true : false}
                  className="selected_gender_rdobtn"
                />

                <FormControlLabel
                  value="other"
                  control={<Radio style={{ color: '#4620B7' }} />}
                  label="Other"
                  disabled={showEditPage === false ? true : false}
                  className="selected_gender_rdobtn"
                />
              </RadioGroup>
              {/* </FormControl> */}
            </Grid>

            <Grid item xs={12} md={6} sm={12} lg={6}>
              <FormControl className="staff-fields">
                <TextField
                  variant="outlined"
                  type="text"
                  onChange={setFormValues}
                  label="Forename(s)"
                  required
                  name="first_name"
                  id="person_first_name"
                  value={fieldValues['first_name'] ? fieldValues['first_name'] : ''}
                  error={fieldError['first_name_error']}
                  helperText={fieldError['first_name_helper']}
                  inputProps={{ maxLength: 255 }}
                  disabled={showEditPage === false ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src="content/icons/Personal Details/Last Name.png" />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} sm={12} lg={6}>
              <FormControl className="staff-fields">
                <TextField
                  variant="outlined"
                  type="text"
                  onChange={setFormValues}
                  label="Last Name"
                  required
                  name="last_name"
                  id="person_last_name"
                  value={fieldValues['last_name'] ? fieldValues['last_name'] : ''}
                  error={fieldError['last_name_error']}
                  helperText={fieldError['last_name_helper']}
                  inputProps={{ maxLength: 255 }}
                  disabled={showEditPage === false ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src="content/icons/Personal Details/Last Name.png" />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} sm={12} lg={6}>
              <FormControl className="staff-fields">
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                  <KeyboardDatePicker
                    autoOk
                    disableFuture
                    variant="inline"
                    inputVariant="outlined"
                    label="Date of Birth"
                    name="date_of_birth"
                    format={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                    value={fieldValues['date_of_birth'] ? fieldValues['date_of_birth'] : null}
                    disabled={showEditPage === false ? true : false}
                    ToolbarComponent={prop => (
                      <>
                        <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                      </>
                    )}
                    placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                    InputAdornmentProps={{ position: 'end' }}
                    onChange={date => handleDateOfBirth(date)}
                  />{' '}
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} sm={12}>
              <FormControl className="phone-num" style={{ marginTop: '0', width: '96%', background: '#0000' }}>
                <PhoneInput
                  inputProps={{
                    name: 'phone',
                    autoFocus: false,
                  }}
                  country={
                    props.settings.country_of_operations
                      ? country_types.find(country => country.value === props.settings.country_of_operations)
                        ? country_types.find(country => country.value === props.settings.country_of_operations).phoneName
                        : 'gb'
                      : 'gb'
                  }
                  value={
                    !isNew
                      ? fieldValues['phoneNumber']
                        ? fieldValues['phoneNumber'][0]
                          ? fieldValues['phoneNumber'][0]['value']
                          : null
                        : null
                      : fieldValues['phoneNumber']
                  }
                  onChange={handlePhoneNumber}
                  placeholder="Phone"
                  disabled={showEditPage === false ? true : false}
                />
                {<span style={{ color: '#bc211d', fontFamily: 'Roboto', fontSize: 13 }}>{fieldError['phoneNumber_helper']}</span>}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} sm={12} lg={12}>
              <FormControl className="staff-fields" style={{ width: '98%' }}>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Email Address"
                  required
                  name="email"
                  onChange={handleEmail}
                  id="person_email"
                  value={
                    !isNew
                      ? fieldValues['email']
                        ? fieldValues['email'][0]
                          ? fieldValues['email'][0]['value']
                          : null
                        : null
                      : fieldValues['email']
                  }
                  inputProps={{ maxLength: 255 }}
                  error={fieldError['email_error']}
                  helperText={fieldError['email_helper']}
                  InputLabelProps={{ shrink: true }}
                  disabled={showEditPage === false ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src="content/icons/Personal Details/Email Address.png" />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>

        <Grid container spacing={2} style={{ marginTop: '1.5rem' }}>
          <Grid item md={6} lg={6} sm={12} xs={12}>
            <FormControl className="staff-fields" style={{ width: '97%' }}>
              <Autocomplete
                options={
                  props.countriesList &&
                  props.countriesList.map(country => ({
                    id: country.id,
                    name: country.name,
                  }))
                }
                noOptionsText={'No Records'}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.name || ''}
                className=" "
                onChange={handleCountryData}
                value={fieldValues['country'] ? fieldValues['country'] : null}
                disabled={showEditPage === false ? true : false}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    className="personAutocompleteNationalityTextfield automaticcompleteLabel"
                    label="Country of Residence"
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item md={6} lg={6} sm={12} xs={12}>
            <FormControl className="staff-fields" style={{ width: '97%' }}>
              <Autocomplete
                options={
                  props.citiesList &&
                  props.citiesList.map(city => ({
                    id: city.id,
                    name: city.name,
                  }))
                }
                noOptionsText={'No Records'}
                ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                getOptionLabel={option => option.name || ''}
                className=" "
                onChange={handleCityData}
                value={fieldValues['city'] ? fieldValues['city'] : null}
                disabled={showEditPage === false ? true : false}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    className="personAutocompleteNationalityTextfield automaticcompleteLabel"
                    label="City of Residence"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: '1.5rem' }} className="AddressFields">
          <Grid item md={6} lg={6} sm={12} xs={12}>
            <FormControl className="staff-fields" style={{ width: '97%' }}>
              <TextField
                variant="outlined"
                type="text"
                onChange={handleAddress}
                label="House/ Flat/ Appartment No."
                required
                name="address"
                id="address"
                value={
                  !isNew
                    ? fieldValues['address']
                      ? fieldValues['address'][0]
                        ? fieldValues['address'][0]['address_line_one']
                        : null
                      : null
                    : fieldValues['address']
                }
                error={fieldError['address_error']}
                helperText={fieldError['address_helper']}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 255 }}
                disabled={!showEditPage}
              />
            </FormControl>
          </Grid>

          <Grid item md={6} lg={6} sm={12} xs={12}>
            <FormControl className="staff-fields" style={{ width: '97%' }}>
              <TextField
                variant="outlined"
                type="text"
                onChange={setFormValues}
                label="Post Code"
                name="postCode"
                id="postCode"
                value={fieldValues['postCode'] ? fieldValues['postCode'] : ''}
                error={fieldError['postCode_error']}
                helperText={fieldError['postCode_helper']}
                inputProps={{ maxLength: 255 }}
                disabled={showEditPage === false ? true : false}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img src="content/icons/Personal Details/Post Code.png" />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Divider style={{ margin: '4% 0' }} />

        <div className="next_of_kin_Div">
          <h1 className="NextOfKinHeading">Next of Kin</h1>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} sm={12} lg={6} className="text_field">
              <FormControl className="staff-fields">
                <TextField
                  variant="outlined"
                  type="text"
                  onChange={handleKinFirstName}
                  label="Forename(s)"
                  name="kin_first_name"
                  id="kin_first_name"
                  value={fieldValues['kin_first_name'] ? fieldValues['kin_first_name'] : null}
                  // InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 255 }}
                  disabled={showEditPage === false ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src="content/icons/Personal Details/Last Name.png" />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} sm={12} lg={6} className="text_field">
              <FormControl className="staff-fields">
                <TextField
                  variant="outlined"
                  type="text"
                  onChange={handleKinLastName}
                  label="Last Name"
                  name="kin_last_name"
                  id="kin_last_name"
                  value={fieldValues['kin_last_name'] ? fieldValues['kin_last_name'] : ''}
                  inputProps={{ maxLength: 255 }}
                  disabled={showEditPage === false ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src="content/icons/Personal Details/Last Name.png" />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid className="text_field" item xs={12} md={6} sm={12} lg={6}>
              <FormControl className="staff-fields">
                <TextField
                  variant="outlined"
                  label="Contact No."
                  name="kin_contact_num"
                  onChange={setFormValues}
                  value={fieldValues['kin_contact_num'] ? fieldValues['kin_contact_num'] : ''}
                  error={fieldError['contact_num_error']}
                  helperText={fieldError['contact_num_helper']}
                  inputProps={{ maxLength: 15 }}
                  disabled={showEditPage === false ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src="content/icons/Personal Details/Contact No..png" />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item md={6} lg={6} sm={12} xs={12}>
              <FormControl className="staff-fields">
                <Autocomplete
                  options={['Partner / Spouse', 'Sibling', 'Parent', 'Friend', 'Other']}
                  noOptionsText={'No Records'}
                  ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                  getOptionLabel={option => option || ''}
                  onChange={handleKinRelation}
                  value={fieldValues['kin_relation'] ? fieldValues['kin_relation'] : null}
                  disabled={showEditPage === false ? true : false}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      className="personAutocompleteNationalityTextfield automaticcompleteLabel"
                      label="Relationship"
                      name="kin_relation"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>

        {fieldValues['country']?.name === 'Malaysia' ? (
          <>
            <Divider style={{ margin: '4% 0' }} />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} sm={12}>
                <FormControl className="staff-fields">
                  <TextField
                    className="personCompanyName"
                    variant="outlined"
                    label="Income Tax Number"
                    name="income_tax_number"
                    value={fieldValues['income_tax_number'] ? fieldValues['income_tax_number']['value'] : ''}
                    onChange={handleIncomeTaxNumber}
                    inputProps={{ maxLength: 13 }}
                    disabled={showEditPage === false ? true : false}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <img src="content/icons/Personal Details/Post Code.png" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} sm={12}>
                <FormControl className="staff-fields">
                  <TextField
                    className="personCompanyName"
                    variant="outlined"
                    label="Passport Number"
                    name="passport_number"
                    disabled={showEditPage === false ? true : false}
                    value={fieldValues['passport_number'] ? fieldValues['passport_number']['value'] : ''}
                    onChange={handlePassportNumber}
                    inputProps={{ minLength: 22 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <img src="content/icons/Personal Details/Post Code.png" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} sm={12}>
                <FormControl className="staff-fields mb-2">
                  <TextField
                    className="CompanyName"
                    variant="outlined"
                    label="Kwsp Number"
                    name="kwsp_number"
                    disabled={showEditPage === false ? true : false}
                    value={fieldValues['kwsp_number'] ? fieldValues['kwsp_number']['value'] : ''}
                    onChange={handleKwspNumber}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <img src="content/icons/Personal Details/Post Code.png" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </>
        ) : null}

        {fieldValues['country']?.name === 'United Kingdom' ? (
          <>
            <Divider style={{ margin: '4% 0' }} />
            <Grid container spacing={2}>
              <Grid item md={6} lg={6} sm={12} xs={12}>
                <FormControl className="staff-fields">
                  <TextField
                    type="text"
                    variant="outlined"
                    onChange={setFormValues}
                    label="National Insurance No"
                    name="national_insurance_number"
                    id="national_insurance_number"
                    disabled={showEditPage === false ? true : false}
                    value={fieldValues['national_insurance_number'] ? fieldValues['national_insurance_number'] : ''}
                    inputProps={{ maxLength: 16 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <img src="content/icons/Personal Details/Post Code.png" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item md={6} lg={6} sm={12} xs={12}>
                <FormControl className="staff-fields">
                  <Autocomplete
                    options={
                      props.allCountriesList &&
                      props.allCountriesList.map(country => ({
                        id: country.id,
                        name: country.name,
                      }))
                    }
                    noOptionsText={'No Records'}
                    ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                    getOptionLabel={option => option.name || ''}
                    className="PersonAutocomplete"
                    onChange={handleNationalitiesData}
                    value={fieldValues['nationality'] ? fieldValues['nationality'] : null}
                    disabled={showEditPage === false ? true : false}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        className="personAutocompleteTextfield autocompleteLabel"
                        label="Nationality"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              {fieldValues['nationality']?.name !== 'United Kingdom' ? (
                <>
                  <Grid item md={3} lg={3} sm={12} xs={12}>
                    <FormControl variant="outlined" className="staff-fields">
                      <Autocomplete
                        options={['Yes', 'No']}
                        noOptionsText={'No Records'}
                        ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                        getOptionLabel={option => option || ''}
                        onChange={visaRequiredhandleChange}
                        value={fieldValues['visa_required'] ? fieldValues['visa_required'] : null}
                        disabled={showEditPage === false ? true : false}
                        renderInput={params => (
                          <TextField
                            {...params}
                            variant="outlined"
                            className="personAutocompleteNationalityTextfield automaticcompleteLabel"
                            label="Right To Work?"
                            name="visa_required"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item lg={3} xs={12} md={3} sm={12}>
                    <FormControl className="staff-fields">
                      <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          autoOk
                          variant="inline"
                          inputVariant="outlined"
                          label="Expiry Date"
                          format={'dd/MM/yyyy'}
                          value={fieldValues['right_to_work_expiry'] ? fieldValues['right_to_work_expiry'] : null}
                          ToolbarComponent={prop => (
                            <>
                              <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                            </>
                          )}
                          placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'dd/MM/yyyy'}
                          InputAdornmentProps={{ position: 'start' }}
                          disabled={showEditPage === false ? true : false}
                          onChange={date => handleRightToWorkExpiry(date)}
                        />{' '}
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Grid>

                  <Grid item md={6} lg={6} sm={12} xs={12}>
                    <FormControl className="staff-fields">
                      <TextField
                        type="text"
                        variant="outlined"
                        onChange={setFormValues}
                        label="Share Code"
                        name="share_code"
                        id="share_code"
                        disabled={showEditPage === false ? true : false}
                        value={fieldValues['share_code'] ? fieldValues['share_code'] : ''}
                        inputProps={{ maxLength: 16 }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <img src="content/icons/Personal Details/Share Code.png" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </>
        ) : null}

        {fieldValues['country']?.name === 'Pakistan' && (
          <>
            <Divider style={{ margin: '4% 0' }} />
            <Grid container spacing={2}>
              <Grid item lg={6} xs={12} md={6} sm={12}>
                <FormControl className="person-fields">
                  <TextField
                    type="text"
                    variant="outlined"
                    onChange={handleCNICNumber}
                    placeholder="Enter your sia number"
                    label="CNIC"
                    name="CNIC"
                    id="person_licence_number"
                    value={fieldValues['CNIC']?.value}
                    inputProps={{ maxLength: 16 }}
                    disabled={showEditPage === false ? true : false}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={12} md={6} sm={12}>
                <FormControl className="dbs-details-fields">
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      label="CNIC Expiry Date"
                      format={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/dd/yyyy'}
                      value={
                        fieldValues['CNIC'] && fieldValues['CNIC']['expiry_date']
                          ? fieldValues['CNIC']['expiry_date']
                          : personEntity && personEntity['CNIC'] && personEntity['CNIC']['expiry_date']
                          ? personEntity['CNIC']['expiry_date']
                          : null
                      }
                      ToolbarComponent={prop => (
                        <>
                          <CustomToolbarComponent {...prop} settingFormat={props.setting.date_format} />
                        </>
                      )}
                      placeholder={props.setting.date_format ? getDateFormat(props.setting.date_format) : 'MM/DD/YYYY'}
                      InputAdornmentProps={{ position: 'start' }}
                      disabled={showEditPage === false ? true : false}
                      onChange={date => handleCNICExpiryDate(date)}
                    />{' '}
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}

        {location.state !== true && showEditPage === true ? (
          <>
            <Divider style={{ margin: '4% 0' }} />
            <Grid item xs={12}>
              <FormControl style={{ flexDirection: 'row', justifyContent: 'flex-end', width: '100%' }}>
                {!isNew ? (
                  <>
                    <Button
                      component={Link}
                      to={isNew && '/staff'}
                      variant="contained"
                      className="Person-profile-Discard-Button"
                      onClick={profilePageHandler}
                    >
                      Cancel
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      onClick={() => {
                        saveEntity(event);
                      }}
                      variant="contained"
                      id="save-entity"
                      type="submit"
                      className="Person-profile-Save-Button"
                    >
                      Save
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      saveEntity(event);
                    }}
                    variant="contained"
                    id="save-entity"
                    type="submit"
                    className="Person-profile-Save-Button"
                  >
                    Next
                  </Button>
                )}
              </FormControl>
            </Grid>
          </>
        ) : null}

        <DeleteModal showDeleteModal={deleteModalSchedule} setShowDeleteModal={setDeleteModalSchedule} deleteStaff={deleteStaff} />
      </Grid>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  personEntity: storeState.person.entity,
  settings: storeState.organizationSettings.storedOrganizationSettings,
  setting: storeState.PersonalSettings.storedPersonalSettings,
  countriesList: storeState.subcontractor.CountryEntity,
  citiesList: storeState.subcontractor.CityEntity,
  allCountriesList: storeState.person.AllCountryEntity,
  personList: storeState.person.entities,
});

const mapDispatchToProps = { getEntity, getCountry, createEntity, getAllCountry, getCity, updateEntity, deleteEntity, reset, resetCountry };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetails);
